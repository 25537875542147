import {
  Card,
  Button,
  Container,
  Row,
  Col,
  CardBody,
  FormGroup,
  Form,
  Input,
  CardHeader,
  Alert,
  Label,
} from 'reactstrap';
import { useContext, useState, useEffect } from 'react';
import { Context as AuthContext } from '../../../contexts/AuthContext';
import { toast } from 'react-toastify';
import TimePicker from '../../../components/Core/TimezonePicker';
import KidsActivity from '../../../services/KidsActivity';
import Upload from '../../../components/Core/Upload';

type ClassSleepProps = {
  kidsIds: string[];
  isNotPhotoPermissionKids?: any[];
  isSleepingKids?: any[];
  onCloseModal: (clear?: boolean) => void;
};

const ClassSleepForm = ({ kidsIds, isNotPhotoPermissionKids, isSleepingKids, onCloseModal }: ClassSleepProps) => {
  const { token }: any = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [notes, setNotes] = useState('');
  const [time, setTime] = useState('');
  const [pictures, setPictures] = useState([] as any[]);
  const [pictureWarning, setPictureWarning] = useState('');
  const [disabled, setDisabled] = useState(false);
  const [activityType, setActivityType] = useState('');

  useEffect(() => {
    if (!kidsIds.length) {
      toast.warn('Please select the students.');
      onCloseModal();
    }
  }, []);

  useEffect(() => {
    if (pictures?.length && isNotPhotoPermissionKids?.length) {
      const kids = isNotPhotoPermissionKids.map((k) => `${k.firstName} ${k.lastName}`);
      setPictureWarning(`Students who are not allowed to upload photos: ${kids.join(', ')}`);
      setDisabled(true);
      return;
    }
    setPictureWarning('');
    setDisabled(false);
  }, [pictures]);

  const closeModal = () => {
    onCloseModal();
  };

  const onChangeFile = (files: any[]) => {
    setPictures(files);
  };

  const handleSubmitForm = async (e: React.FormEvent) => {
    e.preventDefault();
    if (loading) return;

    setLoading(true);

    try {
      const formData = new FormData();
      if (pictures?.length) {
        for (let i = 0; i < pictures.length; i++) {
          formData.append('pictures', pictures[i]);
        }
      }
      for (const k of kidsIds) {
        if (activityType === 'ASLEEP' && isSleepingKids?.includes(k)) continue;
        if (activityType === 'AWAKE' && !isSleepingKids?.includes(k)) continue;
        const result = await KidsActivity(token).sendSleep(k, activityType, notes, time, {});
        if (pictures?.length && result?.data?.id) {
          await KidsActivity(token).pictures(result?.data?.id, formData);
        }
      }
      toast.success(`Saved successfully!`);
      onCloseModal(true);
    } catch (e: any) {
      const message = String(e?.response?.data?.message || 'Create error!');
      toast.error(message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Container fluid>
        <Form onSubmit={handleSubmitForm}>
          <Card>
            {pictureWarning && (
              <CardHeader>
                <Alert color="warning">{pictureWarning}</Alert>
              </CardHeader>
            )}
            <CardBody>
              <Row>
                <Col lg="12">
                  <FormGroup row>
                    <Col md="12">
                      <label className="form-control-label" htmlFor="activityType">
                        Select an Activity
                      </label>
                    </Col>
                    {Number(kidsIds?.length || 0) > Number(isSleepingKids?.length || 0) && (
                      <>
                        <Col md="12">
                          <FormGroup check>
                            <Label check>
                              <Input
                                name="activityType"
                                type="radio"
                                value={'ASLEEP'}
                                onChange={(e) => setActivityType(e.target.value)}
                              />{' '}
                              Sleep
                            </Label>
                          </FormGroup>
                        </Col>
                        <Col md="12">
                          <FormGroup check>
                            <Label check>
                              <Input
                                name="activityType"
                                type="radio"
                                value={'NO_NAP'}
                                onChange={(e) => setActivityType(e.target.value)}
                              />{' '}
                              No Sleep
                            </Label>
                          </FormGroup>
                        </Col>
                      </>
                    )}
                    {Number(isSleepingKids?.length || 0) > 0 && (
                      <Col md="12">
                        <FormGroup check>
                          <Label check>
                            <Input
                              name="activityType"
                              type="radio"
                              value={'AWAKE'}
                              onChange={(e) => setActivityType(e.target.value)}
                            />{' '}
                            Awake
                          </Label>
                        </FormGroup>
                      </Col>
                    )}
                  </FormGroup>
                </Col>
                <Col lg="12">
                  <FormGroup>
                    <label className="form-control-label">Insert a Note</label>
                    <Input type="textarea" onChange={(e) => setNotes(e.target.value)} />
                  </FormGroup>
                </Col>
                <Col lg="12">
                  <FormGroup>
                    <label className="form-control-label">Time</label>
                    <TimePicker
                      value={time}
                      onChangeTime={(time: string) => {
                        setTime(time);
                      }}
                    />
                  </FormGroup>
                </Col>
                <Col lg="12">
                  <Upload accept=".png,.jpg,.jpeg" multiple={true} onChangeFiles={onChangeFile} />
                </Col>
              </Row>
              <Row className="mt-5">
                <Col md="6 text-center">
                  <Button
                    color="primary"
                    type="submit"
                    size="lg"
                    style={{ minWidth: '100px' }}
                    disabled={disabled || loading}
                  >
                    {loading && (
                      <>
                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        {'  Save'}
                      </>
                    )}
                    {!loading && <>Save</>}
                  </Button>
                </Col>
                <Col md="6 text-center">
                  <Button
                    color="secondary"
                    size="lg"
                    style={{ minWidth: '100px' }}
                    onClick={closeModal}
                    disabled={loading}
                  >
                    Cancel
                  </Button>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Form>
      </Container>
    </>
  );
};

export default ClassSleepForm;

import { CardHeader, Row, Col, Button, ButtonProps } from 'reactstrap';

const CardHeaderTopFormButtons = ({ pageTitle, buttons }: { pageTitle: string; buttons?: ButtonProps[] }) => {
  return (
    <CardHeader className="bg-white border-0">
      <Row className="align-items-center">
        <Col xs="8">
          <h3 className="mb-0">{pageTitle}</h3>
        </Col>
        <Col className="text-right" xs="4">
          {buttons?.map((b: any, i: number) => (
            <Button
              key={`buttons-headers-${i}`}
              color={b.color || 'primary'}
              className="float-right mr-2"
              onClick={(e) => b.onClick(e)}
            >
              {b.label}
            </Button>
          ))}
        </Col>
      </Row>
    </CardHeader>
  );
};

export default CardHeaderTopFormButtons;

import CardInfo from '../../../components/Cards/CardInfo';
import ModalContent from '../../../components/Modals/Modal';
import React, { useEffect, useState } from 'react';
import { Row, Col, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import DataTable from '../../../components/Core/DataTable';
import AttendancesService from '../../../services/Attendances';
import { dateFormat } from '../../../helpers/dateFormat';
import moment from 'moment';

export const RatioTeachers: React.FC<{
  token: string;
  currentSchoolId: string;
}> = ({ token, currentSchoolId }) => {
  const [loading, setLoading] = useState(false);
  const [usersNotCheckIn, setUsersNotCheckIn] = useState<any[]>([]);
  const [ratioClass, setRatioClass] = useState<any[]>([]);
  const usersCount = () => {
    return usersNotCheckIn.length || 0;
  };
  const classesCount = () => {
    return ratioClass.filter((i: any) => i.outOfRatio).length || 0;
  };

  const handleFetch = async () => {
    if (!currentSchoolId) return;
    try {
      setLoading(true);

      const { data: data } = await AttendancesService(token || '').listBySchool(currentSchoolId);

      const attendanceKids = data.filter((d: any) => d?.kidsId && d?.kids);
      const attendanceUsers = data.filter((d: any) => d?.userId && d?.user);

      const attendanceUsersGrouped = Object.groupBy(
        attendanceUsers.sort((a: any, b: any) => moment(b.createdAt).diff(moment(a.createdAt))),
        (item: any) => item.userId,
      );

      const notCheckedUser: any[] = [];
      for (const userId of Object.keys(attendanceUsersGrouped)) {
        if (!attendanceUsersGrouped[userId]) {
          continue;
        }
        if (attendanceUsersGrouped[userId][0].attendanceType === 'CHECKIN') {
          continue;
        }
        notCheckedUser.push(attendanceUsersGrouped[userId][0]);
      }
      setUsersNotCheckIn(notCheckedUser);

      const classesInAttendances = attendanceKids
        .filter((i: any) => ['CHECKIN', 'MOVING'].includes(i?.attendanceType))
        .map((i: any) => i.class);

      const classesUniques: any[] = [...new Map(classesInAttendances.map((item: any) => [item['id'], item])).values()];

      const classRatios: any[] = [];
      for (const c of classesUniques) {
        const teachersCheckIn = Object.groupBy(
          attendanceUsers
            .filter((i: any) => ['CHECKIN', 'CHECKOUT'].includes(i.attendanceType) && i.roomId === c.id)
            .sort((a: any, b: any) => moment(b.createdAt).diff(moment(a.createdAt))),
          (item: any) => item.userId,
        );
        const teachersInClass: any[] = [];
        for (const userId of Object.keys(teachersCheckIn)) {
          if (!teachersCheckIn[userId]) {
            continue;
          }
          if (teachersCheckIn[userId][0].attendanceType !== 'CHECKIN') {
            continue;
          }
          teachersInClass.push(teachersCheckIn[userId][0]);
        }

        const childrensEnteredInClass = Object.groupBy(
          attendanceKids
            .filter(
              (i: any) =>
                (['CHECKIN', 'MOVING', 'CHECKOUT'].includes(i.attendanceType) && i.roomId === c.id) ||
                (i.attendanceType === 'MOVING' && i.originRoomId === c.id),
            )
            .sort((a: any, b: any) => moment(b.createdAt).diff(moment(a.createdAt))),
          (item: any) => item.kidsId,
        );
        const childrenInClass: any[] = [];
        for (const kidId of Object.keys(childrensEnteredInClass)) {
          if (!childrensEnteredInClass[kidId]) {
            continue;
          }
          if (childrensEnteredInClass[kidId][0].attendanceType === 'CHECKOUT') {
            continue;
          }
          if (
            childrensEnteredInClass[kidId][0].attendanceType === 'MOVING' &&
            childrensEnteredInClass[kidId][0].originRoomId === c.id
          ) {
            continue;
          }
          childrenInClass.push(childrensEnteredInClass[kidId][0]);
        }

        const outOfRatio =
          c.legalRatioTeachers / c.legalRatioChildrens > teachersInClass.length / childrenInClass.length;

        classRatios.push({
          classId: c?.id,
          nameClass: c?.name || '',
          staffCheckedIn: teachersInClass.length,
          childrenInClass: childrenInClass.length,
          outOfRatio,
        });
      }
      setRatioClass(classRatios);
    } catch (e: any) {
      const message = String(e?.response?.data?.message || 'Error fetching list');
      return console.error(message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    handleFetch();
  }, [currentSchoolId]);

  const [toggleModal, setToggleModal] = useState(false);
  const [activeTab, setActiveTab] = useState('1');

  const customStyles = {
    navLink: {
      minHeight: '42px',
      paddingTop: '8px',
      cursor: 'pointer',
    },
  };

  const columns = [
    {
      name: 'Name',
      route: (row: any) => `/users/${row?.user?.id}/profile`,
      label: (row: any) => `${row?.user?.firstName || '-'} ${row?.user?.lastName || '-'}`,
      grow: 2,
    },
    {
      name: 'Clocked In',
      selector: (row: any) => <>{dateFormat(row?.createdAt)}</>,
      grow: 2,
    },
    {
      name: 'Last Class Checked Out Of',
      selector: (row: any) => <>{row?.user?.lastCheckOutClassName || `-`}</>,
      grow: 2,
    },
  ];

  const columnsRatio = [
    {
      name: 'Class Name',
      route: (row: any) => `/class/${row?.classId}/details`,
      label: (row: any) => `${row?.nameClass || '-'}`,
      grow: 2,
    },
    {
      name: 'Children Checked In',
      selector: (row: any) => <>{row?.childrenInClass}</>,
      grow: 2,
    },
    {
      name: 'Staff Checked In',
      selector: (row: any) => <>{row?.staffCheckedIn}</>,
      grow: 2,
    },
  ];

  const verifyRatio = (row: any) => {
    return row.outOfRatio;
  };

  const conditionalRowStyles = [
    {
      when: (row: any) => verifyRatio(row),
      style: {
        backgroundColor: 'rgb(246, 206, 216)',
      },
    },
  ];

  const handleToggleModal = () => {
    setToggleModal(!toggleModal);
  };

  return (
    <>
      <CardInfo class="bg-primary card-info-mg-button" title="Ratios" icon="fa-clock" toggle={handleToggleModal}>
        <Row className="mt-4">
          <Col md="6" xs="6" className="text-center" style={{ padding: 0, borderRight: '#CCC 1px solid' }}>
            <h2 style={{ fontSize: '25px', fontWeight: 900, color: '#525f7f' }}>{usersCount()}</h2>
            <p style={{ fontSize: '10px' }}>Teachers</p>
          </Col>
          <Col md="6" xs="6" className="text-center" style={{ padding: 0 }}>
            <h2 style={{ fontSize: '25px', fontWeight: 900, color: '#525f7f' }}>{classesCount()}</h2>
            <p style={{ fontSize: '10px' }}>Classrooms</p>
          </Col>
        </Row>
      </CardInfo>
      <ModalContent title="Ratios" modal={toggleModal} toggle={handleToggleModal} size="xl">
        <Row>
          <Col xs="12">
            <Nav tabs>
              <NavItem>
                <NavLink
                  className={`${activeTab === '1' ? 'active' : ''}`}
                  onClick={() => {
                    setActiveTab('1');
                  }}
                  style={customStyles.navLink}
                >
                  Staff Not Checked Into a Class
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={`${activeTab === '2' ? 'active' : ''}`}
                  onClick={() => {
                    setActiveTab('2');
                  }}
                  style={customStyles.navLink}
                >
                  Classroom Ratios
                </NavLink>
              </NavItem>
            </Nav>
          </Col>
          <Col xs="12">
            <TabContent activeTab={activeTab}>
              <TabPane tabId="1">
                <DataTable
                  header={false}
                  title=""
                  columns={[...columns]}
                  data={usersNotCheckIn}
                  totalRows={usersNotCheckIn?.length}
                  loading={loading}
                  button={false}
                  buttonUrl=""
                  onChangeRowsPerPage={(() => {}) as any}
                  onChangePage={(() => {}) as any}
                />
              </TabPane>
              <TabPane tabId="2">
                <DataTable
                  header={false}
                  title=""
                  columns={[...columnsRatio]}
                  data={ratioClass}
                  totalRows={ratioClass?.length || 0}
                  loading={loading}
                  button={false}
                  buttonUrl=""
                  onChangeRowsPerPage={(() => {}) as any}
                  onChangePage={(() => {}) as any}
                  conditionalRowStyles={conditionalRowStyles}
                />
              </TabPane>
            </TabContent>
          </Col>
        </Row>
      </ModalContent>
    </>
  );
};

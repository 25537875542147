import { Link } from 'react-router-dom';
import { useContext, useEffect, useState } from 'react';
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import { Context as AuthContext } from '../../../contexts/AuthContext';
import KidsParentsService from '../../../services/KidsParents';
import KidsParentsInterface from '../../../interfaces/KidsParentsInterface';
import { useParams } from 'react-router-dom';
import CommonHeader from '../../../components/Headers/CommonHeader';
import BillingKids from './BillingKids';
import BillingParents from './BillingParents';
import BillingTabs from './BillingTabs';
import UserService from '../../../services/User';
import isAdmin from '../../../helpers/isAdmin';
import isDirector from '../../../helpers/isDirector';
import isEducationDirectorAdminAssistant from '../../../helpers/isEducationDirectorAdminAssistant';
import Transaction from '../../../services/Transaction';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import moment from 'moment';
import FileDownload from 'js-file-download';
import isUpperManagment from '../../../helpers/isUpperManagment';
import { isMobile } from 'react-device-detect';
import { toast } from 'react-toastify';

const Billing = () => {
  const { token, user }: any = useContext(AuthContext);
  const { kidsId, parentId } = useParams<{ kidsId: string; parentId: string }>();
  const [loading, setLoading] = useState(true);
  const [parents, setParents] = useState([] as any[]);
  const [kidsList, setKidsList] = useState([] as any[]);
  const [kidIdByParent, setKidIdByParent] = useState(null as any);

  useEffect(() => {
    listParents();
  }, [kidsId, parentId]);

  useEffect(() => {
    listParentsKids();
  }, [parents]);

  const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const fileExtension = '.xlsx';
  const exportToCSV = (apiData: any, fileName: string) => {
    const ws = XLSX.utils.json_to_sheet(apiData);
    const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };

  const listParents = async () => {
    try {
      let idKids = kidsId;
      if (parentId) {
        const resultKidsByUser = await UserService(token || '').listKids(parentId);
        idKids = resultKidsByUser.data[0].kidsId;
        setKidIdByParent(idKids);
      }
      const result = await KidsParentsService(token || '').list(idKids);
      const data: KidsParentsInterface[] = result.data;
      setParents(data);
    } catch (e: any) {
      console.error(e?.response?.data?.message || 'List parents error');
    }
  };

  const listParentsKids = async () => {
    if (!parents?.length) return;
    try {
      setLoading(true);
      const list: any[] = [];
      for (const p of parents) {
        if (!p?.isPaying) continue;
        const result = await UserService(token).listKids(p.userId);
        const userKids = result?.data || [];
        for (const uk of userKids) {
          const isExists = list.find((k) => String(k.id) === String(uk.kidsId));
          if (!isExists) {
            list.push(uk.kids);
          }
        }
      }
      if (!list.length) {
        toast.error('No parents paying has found');
      }

      // setKidsList([list.find((item) => item.id === kidsId)]);
      setKidsList(list);
      setLoading(false);
    } catch (e: any) {
      console.error(e?.response?.data?.message || 'Invalid user ID');
      setLoading(false);
    }
  };

  return (
    <>
      <CommonHeader />
      {kidsList.length && (isAdmin(user) || isEducationDirectorAdminAssistant(user)) ? (
        <UncontrolledDropdown nav className="flutter-options">
          <DropdownToggle className="pr-0" nav style={{ paddingLeft: '0' }}>
            <Button>
              <i className="fas fa-cogs"></i>
            </Button>
          </DropdownToggle>
          <DropdownMenu className="dropdown-menu-arrow" right>
            <DropdownItem to={`/billing/${kidsList[0].id}`} tag={Link} className="bg-info text-white">
              <span>Logs</span>
            </DropdownItem>
            <DropdownItem
              tag={Link}
              className="bg-info text-white"
              onClick={async (e) => {
                e.preventDefault();
                const response = await Transaction(token || '').exportAchByKids(kidsList[0].id);
                FileDownload(response, 'ach-activity-' + kidsList[0].id + '.pdf');
              }}
            >
              <span>Export ACH Activity</span>
            </DropdownItem>
            <DropdownItem
              tag={Link}
              className="bg-info text-white"
              onClick={async (e) => {
                e.preventDefault();
                const data = await Transaction(token || '').exportByKids(kidsList[0].id);
                exportToCSV(
                  data,
                  `transactions-${(kidsList[0].firstName + '_' + kidsList[0].lastName).toLowerCase()}-${moment().format(
                    'MMDDYYYYHHmmss',
                  )}`,
                );
              }}
            >
              <span>Export Transactions</span>
            </DropdownItem>
            <DropdownItem to={`/kids/${kidsList[0].id}`} tag={Link} className="bg-info text-white">
              <span>Student Profile</span>
            </DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>
      ) : (
        <></>
      )}
      <Container className="mt--8" fluid>
        <Row>
          <Col className="order-xl-2" xl="12" style={isMobile ? { paddingLeft: 5, paddingRight: 5 } : {}}>
            <Card className="bg-secondary shadow">
              <CardHeader className="bg-white border-0">
                <Row className="align-items-center">
                  <Col xs="8">
                    <h3 className="mb-0">Billing</h3>
                  </Col>
                  {!parentId && (
                    <Col className="text-right" xs="4">
                      <Link to="/billing">
                        <Button color="primary" className="float-right" size={isMobile ? 'sm' : ''}>
                          Back
                        </Button>
                      </Link>
                    </Col>
                  )}
                </Row>
              </CardHeader>
              {!loading && !kidsList.length && (
                <Col>
                  <Card style={{ backgroundColor: '#F6CED8' }}>
                    <CardBody>
                      <Row>
                        <Col>
                          <p>
                            No paying parents found for this student.
                            <br />
                            Please, add a paying parent in <Link to={`/kids/${kidsId}`}>Student Profile</Link>
                          </p>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              )}
              <CardBody style={isMobile ? { padding: '0.5rem' } : {}}>
                <BillingParents parents={parents} kidsList={kidsList} reload={listParentsKids} loading={loading} />
                <BillingKids
                  parents={parents}
                  kidsList={kidsList}
                  reload={() => {
                    listParents();
                    listParentsKids();
                  }}
                  isAdmin={
                    isAdmin(user) ||
                    isDirector(user) ||
                    isEducationDirectorAdminAssistant(user) ||
                    isUpperManagment(user)
                  }
                />
                <BillingTabs loading={loading} kidsList={kidsList} kidsId={kidIdByParent || kidsId} />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Billing;

/* eslint-disable quotes */
/* eslint-disable max-lines */
import { Table, Row, Col, Button } from 'reactstrap';
import { useContext, useState, useEffect } from 'react';
import { Context as AuthContext } from '../../../contexts/AuthContext';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import Pto from '../../../services/Pto';

type EmployeeSettingsFormProps = {
  isOpen: boolean;
};

const PtoPolices = ({ isOpen }: EmployeeSettingsFormProps) => {
  const { token }: any = useContext(AuthContext);

  const [ptoPolices, setPtoPolices] = useState([] as any[]);

  useEffect(() => {
    if (isOpen) {
      listData();
    }
  }, [isOpen]);

  const listData = async () => {
    try {
      const result = await Pto(token).ptoPolicyList();
      setPtoPolices(result.data || []);
    } catch (e: any) {
      toast.error(e?.response?.data?.message || 'No data Found!');
    }
  };

  // disable until we have control to possible delete pto
  // const deleteSettings = async (e: React.FormEvent, id: string) => {
  //   e.preventDefault();
  //   if (loading) return;

  //   setLoading(true);

  //   try {
  //     await Pto(token).delete(id);
  //     toast.success(`Deleted successfully!`);
  //     listData();
  //   } catch (e: any) {
  //     const message = String(e?.response?.data?.message || 'Delete error!');
  //     toast.error(message);
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  return (
    <>
      <Row>
        <Col>
          <Button color="primary">
            <Link to={'/pto-policies-settings'} style={{ color: '#FFFFFF' }}>
              <i className="fas fa-plus" />
              <span>Create New</span>
            </Link>
          </Button>
        </Col>
      </Row>
      <Table className="align-items-center table-flush mt-2" responsive>
        <tbody>
          <tr>
            <th>Title</th>
            <th>Action</th>
          </tr>
          {ptoPolices?.length === 0 && (
            <tr>
              <td colSpan={2} className="text-center">
                Data not found
              </td>
            </tr>
          )}
          {ptoPolices.map((s: any) => (
            <tr key={`employeeSettings-modal-${s.id}`}>
              <td>{s.name}</td>
              <td>
                <Link to={`pto-policies-settings/${s?.id}`}>
                  <i className="fas fa-pencil-alt text-mutted mr-3" style={{ cursor: 'pointer' }} />
                </Link>
                {/* {canDelete && (
                  <i
                    className="far fa-trash-alt text-danger"
                    style={{ cursor: 'pointer' }}
                    onClick={(e) => deleteSettings(e, s.id)}
                  ></i>
                )} */}
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  );
};

export default PtoPolices;

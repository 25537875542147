/* eslint-disable max-lines */
import { useContext, useEffect, useState } from 'react';
import { FormGroup, Input, Row, Col, CardBody, Card, Button } from 'reactstrap';
import DataTable from '../../../components/Core/DataTable';
import ModalContent from '../../../components/Modals/Modal';
import ClassService from '../../../services/Class';
import ClassInterface from '../../../interfaces/ClassInterface';
import { Context as AuthContext } from '../../../contexts/AuthContext';
import KidsService from '../../../services/Kids';
import BillingBulkChargeModal from './BillingBulkChargeModal';
import { toast } from 'react-toastify';
import BillingBulkPriceModal from './BillingBulkPriceModal';
import { createOptionsPage } from '../../../entities/OptionsPaged';
import { createPagedMeta } from '../../../entities/PagedMeta';

export const BillingBulkKidsModal: React.FC<{
  isOpen: boolean;
  bulkType: string;
  toggleModal: () => void;
}> = ({ isOpen, bulkType, toggleModal }) => {
  const isCharge = bulkType === 'CHARGE';
  const isPrice = bulkType === 'PRICE';
  const { token = '', currentSchool = {} }: any = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [chargeModal, setChargeModal] = useState(false);
  const [priceModal, setPriceModal] = useState(false);
  const [classes, setClasses] = useState<ClassInterface[]>([]);
  const [classTypes, setClassTypes] = useState<any[]>([]);
  const [filterClass, setFilterClass] = useState('');
  const [filterClassType, setFilterClassType] = useState('');
  const [kids, setKids] = useState([] as any[]);
  const [kidsSelected, setKidsSelected] = useState([] as any[]);
  const [allSelected, setAllSelected] = useState(false);
  const [updatePriceSheet, setUpdatePriceSheet] = useState(false);

  const kidsList = kids.filter((k: any) => !k.isCheckedIn);
  const [currentKidsList, setCurrentKidsList] = useState(kidsList as any[]);

  const columns = [
    {
      name: <Input type="checkbox" className="mt-n1 ml-2" onChange={(_) => selectAllKids()} checked={allSelected} />,
      selector: (row: any) => (
        <Input
          type="checkbox"
          className="mt-n1 ml-2"
          checked={!!kidsSelected.find((k) => k.id === row.id)}
          onChange={(_) => selectKidsById(row.id)}
        />
      ),
      grow: 1,
    },
    {
      name: 'Child Name',
      route: '/kids',
      label: (row: any) => `${row.firstName || '-'} ${row.lastName || '-'}`,
      grow: 2,
    },
    {
      name: 'Class',
      selector: (row: any) => `${row?.enrollment?.class?.name || '-'}`,
      grow: 2,
    },
  ];

  const selectKidsById = (kidsId: string) => {
    const newKidsSelected = JSON.parse(JSON.stringify(kidsSelected));
    if (newKidsSelected.find((k: any) => k?.id === kidsId)) {
      return setKidsSelected(newKidsSelected.filter((k: any) => k.id !== kidsId));
    }
    const child = kids.find((k) => k.id === kidsId);
    if (child) {
      return setKidsSelected([...newKidsSelected, child]);
    }
  };

  const listClasses = async () => {
    if (!currentSchool?.value) return;
    try {
      setLoading(true);
      const classes = await ClassService(token).listBySchool(currentSchool.value || '');
      if (classes?.data) {
        setClasses(classes.data);
      }
      setLoading(false);
    } catch (e) {
      console.error(e);
      setLoading(false);
    }
  };

  const loadList = async () => {
    try {
      setLoading(true);
      const result = await KidsService(token || '').list(
        {
          filters: [
            {
              field: 'enrollments.status',
              operation: 'in',
              value: ['ENROLLED_NOT_STARTED', 'ENROLLED'],
            },
          ],
          options: createOptionsPage(createPagedMeta()),
        },
        currentSchool.value,
      );
      const data = (result?.data || []).filter((k: any) =>
        ['ENROLLED_NOT_STARTED', 'ENROLLED'].includes(k?.enrollment?.status),
      );
      setKids(data);
      const classTypeList = data
        ?.map((d: any) => ({ id: d?.enrollment?.classTypeId, name: d?.enrollment?.classType?.name }))
        .filter((c: any) => c.id);
      const classTypeFiltered = classTypeList.filter(
        (value: any, index: number, self: any) =>
          index === self.findIndex((t: any) => t.id === value.id && t.name === value.name),
      );
      setClassTypes(classTypeFiltered);
    } catch (e: any) {
      const message = String(e?.response?.data?.message || 'Error fetching list');
      return console.error(message);
    } finally {
      setLoading(false);
    }
  };

  const selectAllKids = () => {
    const kidsFiltered = getFilteredKids();
    if (kidsFiltered.length === kidsSelected.length) {
      setAllSelected(false);
      return setKidsSelected([]);
    }
    setAllSelected(true);
    return setKidsSelected(kidsFiltered);
  };

  const handleChargeModal = () => {
    if (!kidsSelected?.length) {
      return toast.warning('Please select at least one child');
    }
    isCharge ? setChargeModal(true) : setPriceModal(true);
  };

  const finishChargeModal = (e: any) => {
    e?.preventDefault();
    isCharge ? toast.success('Charges created successfully!') : toast.success('Price updated successfully!');
    setChargeModal(false);
    setPriceModal(false);
    setKidsSelected([]);
    setFilterClass('');
    setFilterClassType('');
    setUpdatePriceSheet(false);
    toggleModal();
  };

  useEffect(() => {
    loadList();
  }, []);

  useEffect(() => {
    if (currentSchool.value || '') listClasses();
  }, [currentSchool.value || '']);

  const getFilteredKids = () => {
    let kidsList = kids.filter((k: any) => !k.isCheckedIn);
    if (!filterClass && !filterClassType) {
      return kidsList;
    }
    if (filterClass) {
      kidsList = kidsList.filter((row: any) => row?.enrollment?.class?.id === filterClass);
    }
    if (filterClassType) {
      kidsList = kidsList.filter((row: any) => row?.enrollment?.classTypeId === filterClassType);
    }
    return kidsList;
  };

  const filterByClass = () => {
    const kidsList: any[] = getFilteredKids();
    setCurrentKidsList(kidsList);
  };

  useEffect(() => {
    filterByClass();
  }, [kids]);

  useEffect(() => {
    filterByClass();
    setAllSelected(false);
    setKidsSelected([]);
  }, [filterClass, filterClassType]);

  useEffect(() => {
    const kidsFiltered = getFilteredKids();
    if (kidsSelected?.length !== kidsFiltered?.length) {
      setAllSelected(false);
    }
  }, [kidsSelected]);

  return (
    <>
      <ModalContent
        title={`${isCharge ? 'Post a Charge' : 'Price Change'}`}
        modal={isOpen}
        toggle={toggleModal}
        size="xl"
      >
        <Row>
          <Col xs="12" className="mb-2">
            <Card>
              <CardBody className="pt-1 pb-1">
                <Row>
                  <Col xs="4">
                    <FormGroup>
                      <label className="form-control-label">Class</label>
                      <Input type="select" onChange={(e) => setFilterClass(e.target.value)} value={filterClass}>
                        <option value="">All Classes</option>
                        {classes?.map((c: any, index: number) => {
                          return (
                            <option value={c.id} key={`class-${index}`}>
                              {c.name}
                            </option>
                          );
                        })}
                      </Input>
                    </FormGroup>
                  </Col>
                  {filterClass ? (
                    <Col xs="4">
                      <FormGroup>
                        <label className="form-control-label">Class Type</label>
                        <Input
                          type="select"
                          onChange={(e) => setFilterClassType(e.target.value)}
                          value={filterClassType}
                        >
                          <option value="">All Class Types</option>
                          {classTypes?.map((c: any, index: number) => {
                            return (
                              <option value={c.id} key={`class-type-${index}`}>
                                {c.name}
                              </option>
                            );
                          })}
                        </Input>
                      </FormGroup>
                    </Col>
                  ) : (
                    <></>
                  )}
                  {isPrice && filterClassType ? (
                    <Col xs="4">
                      <FormGroup>
                        <Row>
                          <Col>
                            <label className="form-control-label">Update Price Sheet</label>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <label className="custom-toggle">
                              <Input
                                type="checkbox"
                                onChange={(e) => setUpdatePriceSheet(e.target.checked)}
                                checked={updatePriceSheet}
                              >
                                <option className="custom-toggle-slider rounded-circle" />
                              </Input>
                              <span className="custom-toggle-slider rounded-circle" />
                            </label>
                          </Col>
                        </Row>
                      </FormGroup>
                    </Col>
                  ) : (
                    <></>
                  )}
                </Row>
              </CardBody>
            </Card>
          </Col>
          <Col xs="12" className="mb-3">
            <DataTable
              header={false}
              title=""
              columns={columns}
              data={currentKidsList}
              totalRows={currentKidsList.length}
              loading={loading}
              button={false}
              buttonUrl=""
              onChangeRowsPerPage={(() => {}) as any}
              onChangePage={(() => {}) as any}
            />
          </Col>
          {isCharge ? (
            <Col xs="12">
              <Button color="success" size="md" onClick={() => handleChargeModal()} style={{ float: 'right' }}>
                Bulk Charge
              </Button>
            </Col>
          ) : (
            <></>
          )}
          {isPrice ? (
            <Col xs="12">
              <Button color="success" size="md" onClick={() => handleChargeModal()} style={{ float: 'right' }}>
                Price Change
              </Button>
            </Col>
          ) : (
            <></>
          )}
        </Row>
      </ModalContent>
      <BillingBulkChargeModal
        isOpen={chargeModal}
        toggleModal={() => setChargeModal(!chargeModal)}
        kidsIds={kidsSelected.map((k: { id: string; parents: any[] }) => ({
          id: k.id,
          parentId: k.parents?.[0]?.userId || '',
        }))}
        onChange={finishChargeModal}
      />
      <BillingBulkPriceModal
        isOpen={priceModal}
        updatePriceSheet={updatePriceSheet}
        classId={filterClass}
        classTypeId={filterClassType}
        toggleModal={() => setPriceModal(!priceModal)}
        kidsIds={kidsSelected.map((k: { id: string; parents: any[] }) => ({
          id: k.id,
          parentId: k.parents?.[0]?.userId || '',
        }))}
        onChange={finishChargeModal}
      />
    </>
  );
};

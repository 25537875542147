import { useState } from 'react';
import { Button, Card, CardBody, Col, Modal, Row, Spinner } from 'reactstrap';

type BillingParentOverdueModalProps = {
  isOpen: boolean;
  overdueInvoice: any;
  logout: (e?: any) => void | Promise<void>;
  toggleModal: (e?: any) => void;
  togglePaymentModal: (e?: any) => void;
};

const BillingParentOverdueModal = ({
  isOpen,
  overdueInvoice,
  logout,
  toggleModal,
  togglePaymentModal,
}: BillingParentOverdueModalProps) => {
  const [loading, setLoading] = useState(false);

  const handlePayment = async (e: any) => {
    e?.preventDefault();
    setLoading(false);
    togglePaymentModal();
    toggleModal();
  };

  return (
    <>
      <Modal className="modal-dialog-centered" isOpen={isOpen} toggle={(e: any) => toggleModal(e)}>
        <div className="modal-header pb-1">
          <h5 className="modal-title">Overdue Balance</h5>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={(e) => toggleModal(e)}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div className="modal-body pt-0">
          <Row>
            <Col xl="12">
              <Card className="bg-secondary shadow">
                <CardBody>
                  <Row>
                    {!loading && (
                      <>
                        <Col xl="12">
                          Due to overdue balance of <b>${Number(overdueInvoice?.amount || 0).toFixed(2)}</b>, you must
                          clear your account before{' '}
                          {`${overdueInvoice?.kids?.firstName} ${overdueInvoice?.kids?.lastName}`} can attend.
                          <br />
                          <br />
                          This is due to our policy that balances cannot exceed over 2 weeks of tuition owed.
                        </Col>
                        <Col xl="6" className="mt-4 text-left">
                          <Button
                            color="danger"
                            size="md"
                            style={{ minWidth: '100px' }}
                            onClick={(e: any) => logout(e)}
                          >
                            Logout
                          </Button>
                        </Col>
                        <Col xl="6" className="mt-4 text-right">
                          <Button
                            color="success"
                            size="md"
                            style={{ minWidth: '100px' }}
                            onClick={(e: any) => handlePayment(e)}
                          >
                            Pay Balance Now
                          </Button>
                        </Col>
                      </>
                    )}
                    {loading && (
                      <Col xl="12" className="mt-4 text-center">
                        <span>
                          <Spinner />
                        </span>
                      </Col>
                    )}
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </Modal>
    </>
  );
};

export default BillingParentOverdueModal;

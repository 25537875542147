/* eslint-disable max-lines */
import {
  Container,
  Row,
  Col,
  Card,
  Button,
  Progress,
  CardHeader,
  CardBody,
  Modal,
  Spinner,
  FormGroup,
  InputGroup,
  Input,
  InputGroupAddon,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'reactstrap';
import { useState, useEffect, useMemo } from 'react';
import StripeCheckout from 'react-stripe-checkout';
import { GoogleMap, Marker, useLoadScript } from '@react-google-maps/api';
import { useParams } from 'react-router-dom';
import SchoolService from '../../../services/School';
import WaitingListService from '../../../services/WaitingListPublic';
import ClassTypeService from '../../../services/ClassType';
import PromoCodeService from '../../../services/PromoCodePublic';
import TransactionService from '../../../services/Transaction';
import UserService from '../../../services/User';
import ParentForm from './ParentForm';
import ChildrenForm from './ChildrenForm';
import { toLocaleFormatSlash } from '../../../helpers/dateFormat';
import SuccessPage from './SuccessPage';
import { toast } from 'react-toastify';
import moment from 'moment';

const EnrollForm = () => {
  const { schoolUri } = useParams<{ schoolUri: string }>();
  const secret = new URLSearchParams(window.location.search).get('secret');
  const code = new URLSearchParams(window.location.search).get('code');

  const progressBar = [
    { step: 1, percent: 15 },
    { step: 2, percent: 35 },
    { step: 3, percent: 70 },
    { step: 4, percent: 100 },
  ];

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS || '',
  });
  const center = useMemo(() => ({ lat: 28.47445499709106, lng: -81.27141287241294 }), []);

  const styles = {
    p: {
      fontSize: '14px',
      lineHeight: 1,
    },
    bold: {
      fontWeight: '600',
    },
  };
  const [parents, setParents] = useState([
    {
      id: '',
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
    },
  ] as {
    id?: string;
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
  }[]);

  const [loading, setLoading] = useState(false);
  const [showForm, setShowForm] = useState(true);
  const [formMessage, setFormMessage] = useState('');
  const [franchiseePicture, setFranchiseePicture] = useState('');
  const [classTypeList, setClassTypeList] = useState([] as any[]);
  const [priceList, setPriceList] = useState([] as any[]);
  const [paymentFrequency, setPaymentFrequency] = useState('');
  const [school, setSchool] = useState({} as any);
  const [waitList, setWaitList] = useState({} as any);
  const [step, setStep] = useState(1);
  const [franchiseeName, setFranchiseeName] = useState('');
  const [modalCard, setModalCard] = useState(false);

  const [childId, setChildId] = useState('');
  const [childFirstName, setChildFirstName] = useState('');
  const [childLastName, setChildLastName] = useState('');
  const [childBirthday, setChildBirthday] = useState('');
  const [childStartDate, setChildStartDate] = useState('');
  const [childClassType, setChildClassType] = useState('');

  const [registrationFee, setRegistrationFee] = useState(0);

  const [stripePK, setStripePK] = useState('');

  const [promoCode, setPromoCode] = useState('');
  const [promoCodeApplied, setPromoCodeApplied] = useState(false);
  const [promoCodeError, setPromoCodeError] = useState(false);
  const [promoCodeObj, setPromoCodeObj] = useState({} as any);

  const [lastTransaction, setLastTransaction] = useState({} as any);

  const setParentID = (index: number, value: string) => {
    const newParents = [...parents];
    newParents.forEach((k, i) => {
      if (i == index) {
        k.id = value;
      }
    });
    setParents(newParents);
  };

  const setParentFirstName = (index: number, value: string) => {
    const newParents = [...parents];
    newParents.forEach((k, i) => {
      if (i == index) {
        k.firstName = value;
      }
    });
    setParents(newParents);
  };

  const setParentLastName = (index: number, value: string) => {
    const newParents = [...parents];
    newParents.forEach((k, i) => {
      if (i == index) {
        k.lastName = value;
      }
    });
    setParents(newParents);
  };

  const setParentEmail = (index: number, value: string) => {
    const newParents = [...parents];
    newParents.forEach((k, i) => {
      if (i == index) {
        k.email = value;
      }
    });
    setParents(newParents);
  };

  const setParentPhone = (index: number, value: string) => {
    const newParents = [...parents];
    newParents.forEach((k, i) => {
      if (i == index) {
        k.phone = value;
      }
    });
    setParents(newParents);
  };

  const addParent = () => {
    const newParent = [...parents];
    newParent.push({
      id: '',
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
    });
    setParents(newParent);
  };

  const deleteParent = (index: number) => {
    const newParents = parents.filter((_, i) => index !== i);
    setParents(newParents);
  };

  const getSchool = async () => {
    try {
      const result = await SchoolService('').getSchoolPublic(schoolUri);
      const currentSchool = result?.data || {};
      setSchool(currentSchool);
      setFranchiseeName(currentSchool?.franchisee?.name || '');
      setFranchiseePicture(currentSchool?.franchisee?.picture || '');
      setRegistrationFee(currentSchool?.registrationFee || 0);
    } catch (e) {
      console.error('Error searching for School');
    }
  };

  const getClassTypes = async () => {
    try {
      const result = await ClassTypeService('').listPublic();
      setClassTypeList(result?.data || []);
    } catch (e) {
      console.error('Error listing class types');
    }
  };

  const getPriceSheets = async () => {
    try {
      if (!childClassType) return;
      const result = await ClassTypeService('').listPriceSheetPublic(childClassType);
      setPriceList(result?.data || []);
    } catch (e) {
      console.error('Error listing class types');
    }
  };

  const getWaitList = async () => {
    if (!secret) return;
    try {
      const result = await WaitingListService('').getBySecret(secret);
      const waitingList = result?.data || {};
      setWaitList(waitingList);
    } catch (e) {
      console.error('Error on get Wait List');
    }
  };

  const applyPromoCode = async () => {
    if (!promoCode) return;
    try {
      const result = await PromoCodeService().getCode(promoCode, school?.id);
      const codeResult = result?.data || {};
      if (!codeResult?.id) {
        setPromoCodeError(true);
        setPromoCodeApplied(false);
        return;
      }
      setPromoCodeError(false);
      setPromoCodeApplied(true);
      setPromoCodeObj(codeResult);
    } catch (e) {
      console.error('Error on get promo code');
    }
  };

  const removePromoCode = async () => {
    setPromoCodeError(false);
    setPromoCodeApplied(false);
    setPromoCodeObj({});
  };

  const createOrUpdatePublic = async () => {
    try {
      let firstParent;
      let firstKidsId;
      for (let i = 0; i <= parents?.length; i++) {
        const p = parents[i];
        if (!p?.firstName || !p?.email) continue;
        const data = {
          firstName: p?.firstName || '',
          lastName: p?.lastName || '',
          email: p?.email || '',
          phone: p?.phone || '',
          schoolId: school?.id,
          kids:
            i === 0
              ? [
                  {
                    id: childId || undefined,
                    firstName: childFirstName,
                    lastName: childLastName,
                    birthday: moment(childBirthday).format('YYYY-MM-DD'),
                    potentialDate: moment(childStartDate).format('YYYY-MM-DD'),
                    classTypeId: childClassType,
                    promoCodeId: promoCodeObj?.id,
                    promoCodeDiscountReg: promoCodeObj?.regFee,
                    promoCodeDiscountTuition: promoCodeObj?.tuitionFee,
                  },
                ]
              : [],
          kidsId: i > 0 ? firstKidsId : undefined,
        };
        const result: any = p?.id
          ? await UserService('').updatePublic({ id: p?.id, ...data })
          : await UserService('').createPublic(data);
        const parent: any = result?.data || {};
        if (i === 0) {
          firstParent = parent;
          firstKidsId = parent?.kidsId || '';
        }
        setParentID(i, parent?.id);
      }

      return firstParent;
    } catch (e: any) {
      toast.error(e?.response?.data?.message || 'Save error.');
      console.error('Error on save parent');
    }
  };

  const handlePayment = async (stripeToken: any) => {
    setLoading(true);
    setModalCard(false);
    const p = await createOrUpdatePublic();
    const userId = p?.id || '';
    if (!userId) return;
    try {
      const transaction = await TransactionService('').createDirectPublic({
        userId: userId || '',
        kidsId: p?.kidsId || '',
        schoolId: school?.id || '',
        transactionType: 'CREDIT_CARD',
        amount: registrationFee,
        description: '',
        options: stripeToken,
      });
      setLastTransaction(transaction?.data || {});
      setLoading(false);
    } catch (err: any) {
      toast.error(err?.response?.data?.message || 'Save error.');
      setLoading(false);
      setModalCard(true);
    }
  };

  useEffect(() => {
    setLoading(false);
  }, [lastTransaction]);

  useEffect(() => {
    getSchool();
    getWaitList();
    getClassTypes();
  }, [schoolUri]);

  useEffect(() => {
    if (childClassType) {
      getPriceSheets();
    }
  }, [childClassType]);

  useEffect(() => {
    const newRegistrationFee =
      promoCodeObj?.regFee && school?.registrationFee
        ? Number(
            Number(school?.registrationFee) -
              Number(Number(Number(school?.registrationFee) * (Number(promoCodeObj?.regFee) / 100)).toFixed(2)),
          )
        : 0;
    setRegistrationFee(newRegistrationFee);
  }, [promoCodeObj]);

  useEffect(() => {
    if (priceList?.[0]?.paymentFrequency) {
      const s = String(priceList?.[0]?.paymentFrequency).toLowerCase();
      setPaymentFrequency(s[0].toUpperCase() + s.slice(1));
    }
  }, [priceList]);

  useEffect(() => {
    if (!school?.id) {
      setLoading(false);
      setShowForm(false);
      setFormMessage('Invalid school.');
      return;
    }
    setLoading(false);
    if (school?.onlineEnrollment) {
      setShowForm(true);
      setFormMessage('');
    }
    if (!school?.onlineEnrollment && !secret && !code) {
      setShowForm(false);
      setFormMessage('Sorry, online enrollment is currently unavailable.');
    }
    if (code && school?.enrollSecret && String(school?.enrollSecret) !== String(code)) {
      setShowForm(false);
      setFormMessage('Sorry, the enroll code is invalid.');
    }
  }, [school]);

  useEffect(() => {
    if (secret && !waitList) {
      setShowForm(false);
      setFormMessage('Sorry, the secret code is invalid.');
    }
    if (waitList) {
      setParentID(0, waitList?.user?.id || '');
      setParentFirstName(0, waitList?.user?.firstName || '');
      setParentLastName(0, waitList?.user?.lastName || '');
      setParentEmail(0, waitList?.user?.email || '');
      setParentPhone(0, waitList?.user?.phone || '');
      setChildId(waitList?.kidsList?.[0]?.id || '');
      setChildFirstName(waitList?.kidsList?.[0]?.firstName || '');
      setChildLastName(waitList?.kidsList?.[0]?.lastName || '');
      setChildBirthday(waitList?.kidsList?.[0]?.birthday ? toLocaleFormatSlash(waitList?.kidsList?.[0]?.birthday) : '');
    }
  }, [waitList]);

  useEffect(() => {
    if (step === 4) {
      setModalCard(true);
      setStep(3);
    }
  }, [step]);

  const getSchoolStripeCurrent = async () => {
    if (!school?.id) return;
    try {
      const r = await SchoolService('').getSchoolStripeCurrent(school?.id);
      if (r?.data?.stripePublicKey) {
        setStripePK(r?.data?.stripePublicKey || '');
      }
    } catch (e: any) {
      const message = String(e?.response?.data?.message || 'Stripe current error!');
      console.error(message);
    }
  };

  useEffect(() => {
    getSchoolStripeCurrent();
  }, [school]);

  return (
    <>
      {!lastTransaction?.id ? (
        <>
          <Container fluid>
            <Row>
              <Col md="12">
                <Card className="shadow mt-5 pl-4 pr-4 pb-2 pt-2">
                  {loading ? (
                    <Row>
                      <Col mod="12" className="text-center">
                        <Spinner />
                      </Col>
                    </Row>
                  ) : (
                    <></>
                  )}
                  {!loading && showForm ? (
                    <Row>
                      <Col mod="8">
                        <Row>
                          <Col md="12">
                            <img src={franchiseePicture} style={{ maxWidth: '80px' }} className="mt-3"></img>
                            <p style={{ fontSize: '1em' }} className="mt-1">
                              <strong>
                                {franchiseeName} {school?.name}
                              </strong>
                            </p>
                          </Col>
                          <Col md="12">
                            <h6 className="heading-small text-muted">
                              {step === 1 ? `Parent's Information` : ''}
                              {step === 2 ? `Children Information` : ''}
                              {step === 3 ? `Please review and confirm` : ''}
                            </h6>
                          </Col>
                          {step === 1 ? (
                            <>
                              {parents.map((parent, index: number) => (
                                <Col
                                  key={`parent-${index}`}
                                  md="12"
                                  style={{ paddingLeft: 0, marginLeft: 0 }}
                                  className="mb-2"
                                >
                                  <ParentForm
                                    index={index}
                                    firstName={parent.firstName}
                                    lastName={parent.lastName}
                                    email={parent.email}
                                    phone={parent.phone}
                                    setFirstName={setParentFirstName}
                                    setLastName={setParentLastName}
                                    setEmail={setParentEmail}
                                    setPhone={setParentPhone}
                                    deleteParent={deleteParent}
                                  />
                                </Col>
                              ))}
                              <Col md="12">
                                <Button color="info" size="sm" className="mt-2 mb-2" onClick={addParent}>
                                  + Add another parent
                                </Button>
                              </Col>
                            </>
                          ) : (
                            <></>
                          )}
                          {step === 2 ? (
                            <Col md="12" style={{ paddingLeft: 0, marginLeft: 0 }} className="mb-2">
                              <ChildrenForm
                                index={0}
                                classTypeList={classTypeList}
                                firstName={childFirstName}
                                lastName={childLastName}
                                birthday={childBirthday}
                                startDate={childStartDate}
                                classType={childClassType}
                                setFirstName={(_: number, value: string) => setChildFirstName(value)}
                                setLastName={(_: number, value: string) => setChildLastName(value)}
                                setBirthday={(_: number, value: string) => setChildBirthday(value)}
                                setStartDate={(_: number, value: string) => setChildStartDate(value)}
                                setClassType={(_: number, value: string) => setChildClassType(value)}
                              />
                            </Col>
                          ) : (
                            <></>
                          )}
                          {step === 3 ? (
                            <Col md="12" style={{ paddingLeft: 0, marginLeft: 0 }} className="mb-2">
                              <Card>
                                <CardHeader style={{ paddingBottom: 0 }}>
                                  <h6 className="heading-small text-muted">Parent Info</h6>
                                </CardHeader>
                                <CardBody>
                                  {parents.map((parent, index: number) => (
                                    <div key={`parent-confirm-${index}`}>
                                      {index > 0 ? <hr /> : <></>}
                                      <p style={{ ...styles.p, ...styles.bold }}>
                                        <strong>Name</strong>
                                      </p>
                                      <p style={styles.p}>{`${parent.firstName} ${parent.lastName}`}</p>
                                      <p style={{ ...styles.p, ...styles.bold }}>
                                        <strong>Email</strong>
                                      </p>
                                      <p style={styles.p}>{parent.email}</p>
                                      <p style={{ ...styles.p, ...styles.bold }}>
                                        <strong>Phone Number</strong>
                                      </p>
                                      <p style={styles.p}>{parent.phone}</p>
                                    </div>
                                  ))}
                                </CardBody>
                              </Card>
                              <Card>
                                <CardHeader style={{ paddingBottom: 0 }}>
                                  <h6 className="heading-small text-muted">Children Info</h6>
                                </CardHeader>
                                <CardBody>
                                  <p style={{ ...styles.p, ...styles.bold }}>
                                    <strong>Name</strong>
                                  </p>
                                  <p style={styles.p}>{`${childFirstName} ${childLastName}`}</p>
                                  <p style={{ ...styles.p, ...styles.bold }}>
                                    <strong>Date of Birth</strong>
                                  </p>
                                  <p style={styles.p}>{toLocaleFormatSlash(childBirthday)}</p>
                                  <p style={{ ...styles.p, ...styles.bold }}>
                                    <strong>Class Type</strong>
                                  </p>
                                  <p style={styles.p}>
                                    {childClassType
                                      ? classTypeList.find((c: any) => c.id === childClassType)?.name
                                      : ''}
                                  </p>
                                </CardBody>
                              </Card>
                            </Col>
                          ) : (
                            <></>
                          )}
                        </Row>
                      </Col>
                      <Col md="4">
                        <Row>
                          <Col md="12">
                            <Progress
                              animated
                              className="mt-3 mb-0"
                              value={progressBar.find((p) => p.step === step)?.percent || 0}
                            />{' '}
                            <div style={{ textAlign: 'right', fontSize: '12px' }}>Enrollment Progress</div>
                            <div style={{ height: '150px' }} className="mt-2">
                              {!isLoaded ? (
                                <h1>Loading...</h1>
                              ) : (
                                <GoogleMap mapContainerClassName="map-container" center={center} zoom={15}>
                                  <Marker
                                    position={{
                                      lat: Number(school?.addresses?.[0]?.latitude || 0),
                                      lng: Number(school?.addresses?.[0]?.longitude || 0),
                                    }}
                                  />
                                </GoogleMap>
                              )}
                            </div>
                          </Col>
                          <Col md="12" className="mt-3">
                            <Card>
                              <CardHeader style={{ paddingBottom: 0 }}>
                                <h6 className="heading-small text-muted">Pricing</h6>
                              </CardHeader>
                              <CardBody>
                                <p style={{ ...styles.p, ...styles.bold }}>
                                  <strong>Registration Fee</strong>
                                </p>
                                <p style={styles.p}>
                                  <span
                                    style={{
                                      textDecoration: promoCodeObj?.regFee ? 'line-through' : 'none',
                                      fontSize: promoCodeObj?.regFee ? '12px' : '14px',
                                      marginRight: '5px',
                                    }}
                                  >
                                    {school?.registrationFee
                                      ? `$${Number(school?.registrationFee || 0).toFixed(2)}`
                                      : '-'}
                                  </span>
                                  <span>
                                    {promoCodeObj?.regFee && school?.registrationFee
                                      ? `$${Number(
                                          Number(school?.registrationFee) -
                                            Number(school?.registrationFee) * (Number(promoCodeObj?.regFee) / 100),
                                        ).toFixed(2)}`
                                      : ''}
                                  </span>
                                </p>
                                <p style={{ ...styles.p, ...styles.bold }}>
                                  <strong>Annual Supply Fee</strong>
                                </p>
                                <p style={styles.p}>
                                  {school?.supplyFee ? `$${Number(school?.supplyFee || 0).toFixed(2)}` : '-'}
                                </p>
                                {priceList?.[0]?.tuition ? (
                                  <>
                                    <p style={{ ...styles.p, ...styles.bold }}>
                                      <strong>{`${paymentFrequency} Tuition`}</strong>
                                    </p>
                                    <p style={styles.p}>
                                      <span
                                        style={{
                                          textDecoration: promoCodeObj?.tuitionFee ? 'line-through' : 'none',
                                          fontSize: promoCodeObj?.tuitionFee ? '12px' : '14px',
                                          marginRight: '5px',
                                        }}
                                      >
                                        {priceList?.[0]?.tuition
                                          ? `$${Number(priceList?.[0]?.tuition || 0).toFixed(2)}`
                                          : '-'}
                                      </span>
                                      <span>
                                        {promoCodeObj?.tuitionFee && priceList?.[0]?.tuition
                                          ? `$${Number(
                                              Number(priceList?.[0]?.tuition) -
                                                Number(priceList?.[0]?.tuition) *
                                                  (Number(promoCodeObj?.tuitionFee) / 100),
                                            ).toFixed(2)}`
                                          : ''}
                                      </span>
                                    </p>
                                  </>
                                ) : (
                                  <></>
                                )}
                                {step === 3 ? (
                                  <>
                                    {promoCodeApplied ? (
                                      <>
                                        <p style={{ ...styles.p, ...styles.bold }}>
                                          <strong>PROMO CODE</strong>
                                        </p>
                                        <p style={styles.p}>
                                          {promoCode}{' '}
                                          <i
                                            className="far fa-trash-alt text-danger"
                                            style={{
                                              cursor: 'pointer',
                                            }}
                                            onClick={removePromoCode}
                                          ></i>
                                        </p>
                                      </>
                                    ) : (
                                      <>
                                        <hr />
                                        <FormGroup>
                                          <label className="form-control-label">PROMO CODE</label>
                                          <InputGroup>
                                            <Input
                                              className="form-control-alternative"
                                              placeholder="PROMO CODE"
                                              type="text"
                                              value={promoCode}
                                              onChange={(e) => setPromoCode(e.target.value.toUpperCase())}
                                            />
                                            <InputGroupAddon addonType="append">
                                              <Button color="success" onClick={applyPromoCode}>
                                                Apply
                                              </Button>
                                            </InputGroupAddon>
                                          </InputGroup>
                                        </FormGroup>
                                        {promoCodeError ? (
                                          <span style={{ color: 'red' }}>Invalid Promo Code</span>
                                        ) : (
                                          <></>
                                        )}
                                      </>
                                    )}
                                  </>
                                ) : (
                                  <></>
                                )}
                              </CardBody>
                            </Card>
                          </Col>
                        </Row>
                        <Row>
                          <Col md="6">
                            {step > 1 ? (
                              <Button
                                color="danger"
                                size="sm"
                                className="mt-2 mb-2"
                                onClick={() => setStep(step - 1)}
                                style={{ width: '100%' }}
                              >
                                <i className="fas fa-arrow-left"></i> Back
                              </Button>
                            ) : (
                              <></>
                            )}
                          </Col>
                          <Col md="6">
                            <Button
                              color="primary"
                              size="sm"
                              className="mt-2 mb-2"
                              onClick={() => {
                                if (step === 2 && !childStartDate) {
                                  return toast.warn('Start date is required.');
                                }
                                return setStep(step + 1);
                              }}
                              style={{ width: '100%' }}
                              disabled={!parents?.[0]?.firstName || !parents?.[0]?.lastName ? true : false}
                            >
                              {step <= 2 ? (
                                <>
                                  Next Step <i className="fas fa-arrow-right"></i>
                                </>
                              ) : (
                                <>Confirm</>
                              )}
                            </Button>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  ) : (
                    <Row>
                      <Col mod="12" className="text-center">
                        {formMessage}
                      </Col>
                    </Row>
                  )}
                </Card>
              </Col>
            </Row>
          </Container>
          <Modal
            className="modal-dialog-centered"
            size="md"
            isOpen={modalCard}
            toggle={(_: any) => setModalCard(false)}
          >
            <ModalHeader>Registration Fee</ModalHeader>
            <ModalBody>
              <span>{`This registration fee is a one-time, non-refundable, fee of $${Number(
                registrationFee || 0,
              ).toFixed(2)} unless otherwise specified.`}</span>
            </ModalBody>
            <ModalFooter>
              <Button color="danger" onClick={(_: any) => setModalCard(false)}>
                Cancel
              </Button>
              <StripeCheckout
                stripeKey={stripePK}
                token={handlePayment}
                name="Stripe Payment"
                panelLabel={`Pay`}
                currency="USD"
                amount={registrationFee * 100}
              >
                <Button color="primary">Agree and pay</Button>{' '}
              </StripeCheckout>
            </ModalFooter>
          </Modal>
        </>
      ) : (
        <SuccessPage />
      )}
    </>
  );
};

export default EnrollForm;

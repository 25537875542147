/* eslint-disable max-lines */
import { useContext, useEffect, useState } from 'react';
import { Button, Col, FormGroup, Input, InputGroup, InputGroupAddon, Modal, Row, Spinner } from 'reactstrap';
import NumberFormat from 'react-number-format';
import { Context as AuthContext } from '../../../contexts/AuthContext';
import TransactionService from '../../../services/Transaction';
import { validTransactionTypes } from '../../../interfaces/TransactionsInterface';
import UserCustomer from '../../../services/UserCustomer';
import InvoicesService from '../../../services/Invoices';
import { toast } from 'react-toastify';
import { createPagedMeta } from '../../../entities/PagedMeta';
import { createOptionsPage } from '../../../entities/OptionsPaged';

type BillingCardPaymentModalProps = {
  parents: any[];
  kidsList: any[];
  invoicesList: any[];
  isOpen: boolean;
  toggleModal: (e?: any) => void;
  onChange: (e?: any, amount?: number) => void;
};

const BillingCardPaymentModal = ({
  parents,
  kidsList,
  isOpen,
  toggleModal,
  onChange,
}: BillingCardPaymentModalProps) => {
  const { token, currentSchool }: any = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [userId, setUserId] = useState('');
  const [invoiceId, setInvoiceId] = useState('');
  const [description, setDescription] = useState('');
  const [amount, setAmount] = useState(0);

  const [kidsPercents, setKidsPercent] = useState([] as { id: string; name: string; percentage: number }[]);
  const [cardMethods, setCardMethods] = useState([] as any[]);
  const [paymentMethodId, setPaymentMethod] = useState('');
  const [cardDetails, setCardDetails] = useState('');

  const [invoices, setInvoices] = useState([] as any[]);
  const [invoiceAmount, setInvoiceAmount] = useState(false);

  useEffect(() => {
    setLoading(false);
    setUserId('');
    setInvoiceId('');
    setDescription('');
    setAmount(0);
    setKidsPercent([]);
    setCardMethods([]);
    setPaymentMethod('');
    setCardDetails('');
    setInvoices([]);
    setInvoiceAmount(false);
    listInvoices();
  }, [isOpen, currentSchool]);

  useEffect(() => {
    if (invoiceId === 'ALL') {
      let invoicesAmount = 0;
      for (const i of invoices) {
        invoicesAmount = invoicesAmount + Number(i?.amount);
      }
      setAmount(invoicesAmount);
      // const invoice = invoices.find((i) => i?.id === invoiceId);
      // if (!invoice) return;
      // setAmount(Number(invoice?.amount));
      setInvoiceAmount(true);
      return;
    }
    setAmount(0);
    setInvoiceAmount(false);
  }, [invoiceId]);

  useEffect(() => {
    const percents = [];
    for (const k of kidsList) {
      percents.push({ id: k.id, name: `${k.firstName || ''} ${k.lastName || ''}`, percentage: 0 });
    }
    setKidsPercent(percents);
  }, [isOpen]);

  useEffect(() => {
    getUserSavedCards();
  }, [userId]);

  useEffect(() => {
    const cardData = cardMethods.find((a) => a?.id === paymentMethodId);
    setCardDetails(
      cardData?.card?.brand
        ? `${String(cardData?.card?.brand).toUpperCase()} - ${cardData?.card?.exp_month}/${cardData?.card?.exp_year}`
        : '',
    );
  }, [paymentMethodId]);

  const getUserSavedCards = async () => {
    if (!userId) return;
    setLoading(true);
    const parent = parents.find((p: any) => p?.userId === userId);
    const result = await UserCustomer(token).create({
      userId: userId || '',
      schoolId: currentSchool?.value || '',
      options: { email: parent?.user?.email || '' },
    });
    const customer = result?.data || {};
    const card = customer?.options?.paymentMethods?.CREDIT_CARD || [];
    setCardMethods(card);
    setLoading(false);
  };

  const listInvoices = async () => {
    try {
      const child = kidsList?.find((item) => item.id);
      if (!child?.id) {
        return;
      }

      setLoading(true);

      const filter = {
        field: 'kidsId',
        operation: 'in',
        value: kidsList.map((item) => item.id),
      };
      const filterStatus = {
        field: 'status',
        operation: '=',
        value: 'NEW',
      };
      const filteredOptions: any = {
        filters: [filter, filterStatus],
        options: createOptionsPage(createPagedMeta()),
      };

      const result = await InvoicesService(token || '').listByKids(child?.id, filteredOptions);
      setInvoices(result?.data);
    } catch (e: any) {
      const message = String(e?.response?.data?.message || 'Error fetching list');
      console.error(message);
    } finally {
      setLoading(false);
    }
  };

  const handleRequest = async (e: any) => {
    if (loading) return;
    e.preventDefault();

    const percentage = kidsPercents.reduce(
      (previousValue: number, k: any) => Number(previousValue) + Number(k?.percentage || 0),
      0,
    );
    if (percentage !== 100 && invoiceId !== 'ALL') {
      toast.error('The sum of the percentages must be 100!');
      setLoading(false);
      return;
    }

    setLoading(true);
    try {
      if (invoiceId !== 'ALL') {
        await TransactionService(token).createPayment({
          kidsPercents,
          userId: userId,
          transactionType: validTransactionTypes.CREDIT_CARD,
          amount,
          invoiceId,
          description,
          paymentMethodId,
        });
      } else {
        for (const i of invoices) {
          const invoiceKidsPercents = [
            { id: i?.kids?.id, name: `${i?.kids?.firstName} ${i?.kids?.lastName}`, percentage: 100 },
          ];
          await TransactionService(token).createPayment({
            kidsPercents: invoiceKidsPercents,
            userId: userId,
            transactionType: validTransactionTypes.CREDIT_CARD,
            amount: Number(i?.amount),
            invoiceId: i?.id,
            description,
            paymentMethodId,
          });
        }
      }
      onCardModalChange(e);
      setLoading(false);
    } catch (e: any) {
      toast.error(e?.response?.data?.message || 'Request error');
      setLoading(false);
    }
  };

  const toggleCardModal = (e: any) => {
    e.preventDefault();
    toggleModal(e);
    setDescription('');
    setAmount(0);
  };

  const onCardModalChange = (e: any) => {
    onChange(e);
    e.preventDefault();
    setDescription('');
    setAmount(0);
  };

  const onKidsPercentChange = (id: string, floatValue: number) => {
    const newKidsPercent = JSON.parse(JSON.stringify(kidsPercents));
    for (const k of newKidsPercent) {
      if (k.id === id) {
        k.percentage = floatValue;
      }
    }
    setKidsPercent(newKidsPercent);
  };

  return (
    <>
      <Modal className="modal-dialog-centered" size="xl" isOpen={isOpen} toggle={(e: any) => toggleCardModal(e)}>
        <div className="modal-header pb-1">
          <h5 className="modal-title">Credit Card Charge</h5>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={(e) => toggleCardModal(e)}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div className="modal-body pt-0">
          <Row className="mt-2">
            <Col lg="6">
              <FormGroup>
                <label className="form-control-label">Parent</label>
                <Input type="select" onChange={(e) => setUserId(e.target.value)} required>
                  <option value="">Please select</option>
                  {parents.map((p: any, index: number) => {
                    return (
                      <option value={p.userId} key={`parent-${index}`}>
                        {`${p?.user?.firstName || ''} ${p?.user?.lastName || ''}`}
                      </option>
                    );
                  })}
                </Input>
              </FormGroup>
            </Col>
            <Col lg="6">
              <FormGroup>
                <label className="form-control-label">Invoice</label>
                <Input type="select" onChange={(e) => setInvoiceId(e.target.value)}>
                  <option value="">Extra Charge (don't link with an invoice)</option>
                  <option value="ALL">All Invoices</option>
                  {/* {invoices.map((a: any, index: number) => {
                    return (
                      <option value={a?.id} key={`invoice-${index}`}>
                        {`Invoice ${a?.number} - Amount $${Number(a?.amount || 0).toFixed(2)}`}
                      </option>
                    );
                  })} */}
                </Input>
              </FormGroup>
            </Col>
            <Col lg="6">
              <FormGroup>
                <label className="form-control-label">Description</label>
                <Input
                  className="form-control-alternative"
                  placeholder="Please, insert a description."
                  type="text"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                />
              </FormGroup>
            </Col>
            <Col lg="6">
              <FormGroup>
                <label className="form-control-label">Amount</label>
                <Input
                  type="text"
                  thousandsGroupStyle="thousand"
                  prefix="$"
                  decimalSeparator="."
                  displayType="input"
                  thousandSeparator={true}
                  allowNegative={false}
                  decimalScale={2}
                  tag={NumberFormat}
                  value={amount}
                  onValueChange={(e: any) => setAmount(e.floatValue)}
                  disabled={invoiceAmount}
                />
              </FormGroup>
            </Col>
            <Col lg="6">
              <FormGroup>
                <label className="form-control-label">Card Number</label>
                <Input type="select" onChange={(e) => setPaymentMethod(e.target.value)}>
                  {loading && <option value="">Loading...</option>}
                  {!loading && (
                    <>
                      <option value="">Please select</option>
                      {cardMethods.map((a: any, index: number) => {
                        return (
                          <option value={a.id} key={`account-${index}`}>
                            {a?.card?.last4 ? `********${a?.card?.last4}` : '-'}
                          </option>
                        );
                      })}
                    </>
                  )}
                </Input>
              </FormGroup>
            </Col>
            <Col lg="6">
              <FormGroup>
                <label className="form-control-label">Card Details</label>
                <Input
                  className="form-control-alternative"
                  placeholder="Please, select a card number"
                  type="text"
                  value={cardDetails}
                  disabled
                />
              </FormGroup>
            </Col>

            {invoiceId === 'ALL' ? (
              <>
                <Col lg="12">
                  <h5>Invoices:</h5>
                  <ul>
                    {invoices.map((a: any, index: number) => (
                      <li key={`inv-${index}`}>{`Number ${a?.number} - Amount $${Number(a?.amount || 0).toFixed(
                        2,
                      )}`}</li>
                    ))}
                  </ul>
                </Col>
              </>
            ) : (
              <>
                <Col lg="12">
                  <h5>Kids/Percentage</h5>
                </Col>
                {kidsPercents.map((k: any, index: number) => (
                  <Col lg="3" key={`child-${index}`}>
                    <FormGroup>
                      <label className="form-control-label">{`${k?.name} `}</label>

                      <InputGroup>
                        <Input
                          type="text"
                          thousandsGroupStyle="thousand"
                          suffix="%"
                          decimalSeparator="."
                          displayType="input"
                          thousandSeparator={true}
                          allowNegative={false}
                          decimalScale={2}
                          tag={NumberFormat}
                          value={k?.percentage}
                          onValueChange={(e: any) => onKidsPercentChange(k.id, e.floatValue)}
                        />

                        <InputGroupAddon addonType="append">
                          <Button color="primary" disabled={true}>
                            ${!amount || !k?.percentage ? '0.00' : Number((k?.percentage / 100) * amount).toFixed(2)}
                          </Button>
                        </InputGroupAddon>
                      </InputGroup>
                    </FormGroup>
                  </Col>
                ))}
              </>
            )}
          </Row>
          <hr />
          <Row>
            <Col md="6 text-center">
              <Button
                color="secondary"
                size="md"
                style={{ minWidth: '100px' }}
                onClick={(e) => toggleCardModal(e)}
                disabled={loading}
              >
                Cancel
              </Button>
            </Col>
            <Col md="6 text-center">
              <Button
                color="success"
                size="md"
                style={{ minWidth: '100px' }}
                onClick={(e) => handleRequest(e)}
                disabled={loading}
              >
                {loading ? <Spinner /> : 'Save'}
              </Button>
            </Col>
          </Row>
        </div>
      </Modal>
    </>
  );
};

export default BillingCardPaymentModal;

/* eslint-disable max-lines */
import { Input } from 'reactstrap';
import { useContext, useState, useEffect } from 'react';
import UserService from '../../../../services/User';
import { Context as AuthContext } from '../../../../contexts/AuthContext';
import { toast } from 'react-toastify';

const NotificationPermissionsList = (params: {
  userId: string;
  setNotifications: any;
  showOnlyThisNotificationsOptions?: any[];
}) => {
  const { token }: any = useContext(AuthContext);

  const [prevNotificationPermissions, setPrevNotificationPermissions] = useState<any>({});
  const [notificationPermissions, setNotificationPermissions] = useState<any>({});

  const setNotificationPermissionsItem = (index: string, value: boolean) => {
    const notificationFiltred = {};
    const notificationOptions = {
      newMessage: index == 'newMessage' ? value : notificationPermissions.newMessage,
      postWithMyChildren: index == 'postWithMyChildren' ? value : notificationPermissions.postWithMyChildren,
      // billing: index == 'billing' ? value : notificationPermissions.billing,
      whenMyChildrenCheck: index == 'whenMyChildrenCheck' ? value : notificationPermissions.whenMyChildrenCheck,
      whenImCheck: index == 'whenImCheck' ? value : notificationPermissions.whenImCheck,
      tourListForToday: index == 'tourListForToday' ? value : notificationPermissions.tourListForToday,
      // timeCardApproval: index == 'timeCardApproval' ? value : notificationPermissions.timeCardApproval,
      ptoRequest: index == 'ptoRequest' ? value : notificationPermissions.ptoRequest,
      employeeBirthdays: index == 'employeeBirthdays' ? value : notificationPermissions.employeeBirthdays,
    };
    if (params?.showOnlyThisNotificationsOptions?.length) {
      Object.keys(notificationOptions)
        .filter((e) => params?.showOnlyThisNotificationsOptions?.indexOf(e) !== -1)
        .forEach((e) => {
          (notificationFiltred as any)[e] = (notificationOptions as any)[e];
        });
    }
    setNotificationPermissions(Object.keys(notificationFiltred).length ? notificationFiltred : notificationOptions);
  };

  const getByUserId = async () => {
    try {
      const result = await UserService(token).show(params.userId);
      if (result?.data) {
        let notificationOptions = result?.data.configs.notificationPermissions;
        if (params?.showOnlyThisNotificationsOptions?.length) {
          notificationOptions = {};
          Object.keys(result?.data.configs.notificationPermissions)
            .filter((e) => params?.showOnlyThisNotificationsOptions?.indexOf(e) !== -1)
            .forEach((e) => {
              (notificationOptions as any)[e] = result?.data.configs.notificationPermissions[e];
            });
        }
        setNotificationPermissions(
          Object.keys(notificationOptions || {}).length === 0 ? notificationPermissions : notificationOptions,
        );
      }
    } catch (e: any) {
      toast.error(e?.response?.data?.message || 'Invalid user ID');
    }
  };

  const camelToNotifyTitle = (str: string) => str.replace(/[A-Z]/g, (letter) => ` ${letter.toLowerCase()}`);

  useEffect(() => {
    getByUserId();
  }, [params.userId]);

  useEffect(() => {
    if (Object.keys(notificationPermissions).length === 0 || Object.keys(prevNotificationPermissions).length === 0) {
      setPrevNotificationPermissions(notificationPermissions);
      return;
    }

    setPrevNotificationPermissions(notificationPermissions);
    params.setNotifications(notificationPermissions);
  }, [notificationPermissions]);

  return (
    <ul style={{ margin: 0, padding: 0, listStyle: 'none' }}>
      {Object.keys(notificationPermissions).map((item, i) => {
        return (
          <li style={{ padding: '5px 0' }} key={`notifyuserlist-${i}`}>
            <div style={{ float: 'left' }}>
              <label className="form-control-label" style={{ textTransform: 'capitalize' }}>
                {camelToNotifyTitle(item)}
              </label>
            </div>
            <div style={{ float: 'right' }}>
              <label className="custom-toggle">
                <Input
                  type="checkbox"
                  onChange={(e) => setNotificationPermissionsItem(item, e.target.checked)}
                  checked={(notificationPermissions as any)[item]}
                >
                  <option className="custom-toggle-slider rounded-circle" />
                </Input>
                <span className="custom-toggle-slider rounded-circle" />
              </label>
            </div>
            <div style={{ clear: 'both' }}></div>
          </li>
        );
      })}
    </ul>
  );
};

export default NotificationPermissionsList;

import { Input, Table, Button, Form } from 'reactstrap';
import { useState, useEffect, useContext, useImperativeHandle, forwardRef } from 'react';
import { Context as AuthContext } from '../../contexts/AuthContext';
import Employee from '../../services/Employee';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
const FormStep3 = forwardRef(({ id }: { id: string }, ref) => {
  const history = useHistory();
  const { token, currentSchool }: any = useContext(AuthContext);
  const [searchStaffName, setSearchStaffName] = useState('');
  const [employees, setEmployees] = useState<any[]>();

  const proccessSubmit = async (userIds: string[]) => {
    try {
      await Employee(token).updateMultiplePtoPolices(userIds, id);
      toast.success('Users updated!');
      history.push('/employee-settings');
    } catch (e: any) {
      const message = String(e?.response?.data?.message || 'Error updating users!');
      toast.error(message);
    }
  };

  useImperativeHandle(ref, () => ({
    async submit() {
      const formData = new FormData(document.getElementById('form-users') as HTMLFormElement);
      await proccessSubmit(Object.values(Object.fromEntries(formData)) as string[]);
    },
  }));

  const handleFormSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const formData = new FormData(e.target as HTMLFormElement);
    await proccessSubmit(Object.values(Object.fromEntries(formData)) as string[]);
  };

  useEffect(() => {
    const loadStaff = async () => {
      const result = await Employee(token).listEmployeesBySchool(currentSchool.value, '');
      setEmployees(result?.data);
    };
    loadStaff();
  }, [currentSchool]);

  return (
    <Form onSubmit={(e) => handleFormSubmit(e)} id="form-users">
      <div className="p-5 pt-2">
        <h1 className="text-center">Who is covered by this policy?</h1>
        <p className="text-center mt-4">
          Select the individuals who fall under these polices from the list below. People can only be assigned to one
          set of polices.
        </p>

        <div className="mt-4 p-2">
          <div className="d-flex align-items-center">
            {/* <Input type="checkbox" style={{ transform: 'scale(1.5)', width: '61px' }} /> */}
            <div className="d-flex justify-content-start">
              <Input
                type="text"
                placeholder="Search by Name"
                style={{ width: '250px' }}
                value={searchStaffName}
                onChange={(event: any) => setSearchStaffName(event.target.value)}
              />
            </div>
          </div>
          <Table className="mt-2" responsive>
            <tbody>
              <tr>
                <th></th>
                <th>Name</th>
                <th>Currrent Policy</th>
                <th>Location</th>
                <th>Department</th>
                <th>Employment Type</th>
                <th>Compensation</th>
              </tr>
              {employees?.map((employee) => (
                <tr
                  key={`employee-${employee.id}`}
                  style={
                    !employee.user?.firstName.toLowerCase().includes(searchStaffName.toLowerCase()) &&
                    !employee.user?.lastName.toLowerCase().includes(searchStaffName.toLowerCase())
                      ? { display: 'none' }
                      : {}
                  }
                >
                  <td>
                    <Input
                      name={`selectedEmployee[${employee.user?.id}]`}
                      value={employee.user?.id}
                      type="checkbox"
                      style={{ transform: 'scale(1.5)' }}
                    />
                  </td>
                  <td>{`${employee.user?.firstName} ${employee.user?.lastName}`}</td>
                  <td>{employee.ptoPolicy?.name || '-'}</td>
                  <td>{employee.user?.addresses[0]?.city || '-'}</td>
                  <td>{employee.department?.name}</td>
                  <td>
                    {employee.workday === 'FT' && 'Full Time'}
                    {employee.workday === 'PT' && 'Part Time'}
                  </td>
                  <td>{employee.paymentFrequency}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
        <div className="d-flex align-items-center justify-content-end mt-6">
          <Button color="warning">Save & Continue</Button>
        </div>
      </div>
    </Form>
  );
});
export default FormStep3;

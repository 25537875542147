/* eslint-disable max-lines */
import {
  Container,
  Row,
  Col,
  FormGroup,
  Input,
  Card,
  CardHeader,
  CardBody,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import { useContext, useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { Context as AuthContext } from '../../../contexts/AuthContext';
import CommonHeader from '../../../components/Headers/CommonHeader';
import KidsService from '../../../services/Kids';
import { FilteredOptionsInterface } from '../../../entities/FilteredOptions';
import { PagedMetaInterface, createPagedMeta } from '../../../entities/PagedMeta';
import { createOptionsPage, OptionsPagedInterface } from '../../../entities/OptionsPaged';
import DataTable from '../../../components/Core/DataTable';
import CardWidget from '../../../components/Cards/CardWidget';
import { BillingBulkKidsModal } from './BillingBulkKidsModal';
import { FilterInterface } from '../../../entities/FilteredOptions';
import isAdmin from '../../../helpers/isAdmin';
import { isMobile } from 'react-device-detect';

const BillingList = () => {
  const isSmall = isMobile && window.innerWidth < 768;
  const isTablet = window.innerWidth <= 1112 && window.innerWidth > 768 && isMobile;
  const { token, currentSchool, user } = useContext(AuthContext);

  const columns: any[] = [
    {
      name: 'Child Name',
      wrap: true,
      selector: (row: any) => (
        <a href={`billing/${row.id}`} className={`${verifyLastTransactionStatus(row) ? '' : ''}`}>{`${
          row?.firstName || '-'
        } ${row?.lastName || '-'}`}</a>
      ),
    },
  ];

  if (!isTablet && !isMobile) {
    columns.push({
      name: 'Class',
      selector: (row: any) => `${row?.enrollment?.class?.name || '-'}`,
      grow: 2,
    });
  }

  columns.push({
    name: 'Tuition',
    selector: (row: any) => row?.billingSettings?.amount,
    wrap: true,
    cell: (row: any) =>
      `$ ${row?.billingSettings?.amount ? Number(row?.billingSettings?.amount || 0).toFixed(2) : '0.00'}`,
  });
  columns.push({
    name: 'Current Balance',
    selector: (row: any) => row?.balance?.amount,
    wrap: true,
    cell: (row: any) => {
      const amount = Number(row?.balance?.amount || 0) * -1;
      return `$ ${amount.toFixed(2)}`;
    },
  });
  columns.push({
    name: 'Auto Charge',
    selector: (row: any) => row?.billingSettings?.autochargeTurnedOn,
    wrap: true,
    cell: (row: any) =>
      row?.billingSettings?.autochargeTurnedOn ? (
        <i className="far fa-check-circle text-success" style={{ fontSize: '16px' }}></i>
      ) : (
        <i className="far fa-times-circle text-danger" style={{ fontSize: '16px' }}></i>
      ),
  });

  if (!isTablet && !isMobile) {
    columns.push({
      name: 'Overdue',
      selector: (row: any) => row?.overdue,
      grow: 1,
      cell: (row: any) =>
        row?.overdue ? <i className="fas fa-times-circle text-danger" style={{ fontSize: '16px' }}></i> : <></>,
    });
  }

  const [data, setData] = useState([] as any[]);
  const [billingNotTurnedOn, setBillingNotTurnedOn] = useState(0);
  const [autochargeTurnedOn, setAutochargeTurnedOn] = useState(0);
  const [overdue, setOverdue] = useState(0);
  const [totalBillingAmount, setTotalBillingAmount] = useState(0);
  const [currentFilter, setCurrentFilter] = useState('ALL');
  const filtersList = [
    { value: 'ALL', label: 'All' },
    { value: 'BILLING', label: 'Billing Not Turned On' },
    { value: 'AUTOCHARGE', label: 'Autocharge Not Turned On' },
    { value: 'OVERDUE', label: 'Overdue Accounts' },
  ];
  const [loading, setLoading] = useState(false);
  const [pagedMeta, setPagedMeta] = useState(createPagedMeta());
  const [filteredOptions, setFilteredOptions] = useState({
    filters: [
      {
        field: 'enrollments.status',
        operation: 'in',
        value: ['ENROLLED_NOT_STARTED', 'ENROLLED'],
      },
    ],
    options: createOptionsPage(pagedMeta),
  } as { filters: FilterInterface[]; options: OptionsPagedInterface });
  const [bulkModal, setBulkModal] = useState(false);
  const [bulkType, setBulkType] = useState('');

  useEffect(() => {
    loadList(filteredOptions);
  }, [filteredOptions, currentSchool]);

  useEffect(() => {
    loadList(filteredOptions);
  }, [currentFilter, bulkModal]);

  const showBulkModal = (newType: string) => {
    setBulkType(newType);
    setBulkModal(!bulkModal);
  };

  const loadFilterList = (data: any[]) => {
    const list = data;
    let newData = [];
    if (currentFilter === 'ALL') {
      newData = list;
    }
    if (currentFilter === 'BILLING') {
      newData = list.filter((k: any) => !k?.billingSettings?.billingTurnedOn);
    }
    if (currentFilter === 'AUTOCHARGE') {
      newData = list.filter((k: any) => !k?.billingSettings?.autochargeTurnedOn);
    }
    if (currentFilter === 'OVERDUE') {
      newData = list.filter((k: any) => k?.overdue);
    }
    setData(newData);
  };

  const loadList = async (filteredOptions: FilteredOptionsInterface) => {
    try {
      setLoading(true);
      const options = JSON.parse(JSON.stringify(filteredOptions));
      const result = await KidsService(token || '').list(options, currentSchool.value);
      const data = (result?.data || []).filter((k: any) =>
        ['ENROLLED_NOT_STARTED', 'ENROLLED'].includes(k?.enrollment?.status),
      );
      data.map((k: any) => {
        k.overdue = k?.balance?.amount < 0;
      });
      loadFilterList(data);
      const billingNotTurnedOn = data.filter((k: any) => !k?.billingSettings?.billingTurnedOn);
      setBillingNotTurnedOn(billingNotTurnedOn?.length || 0);
      const autochargeTurnedOn = data.filter((k: any) => !k?.billingSettings?.autochargeTurnedOn);
      setAutochargeTurnedOn(autochargeTurnedOn?.length || 0);
      const overdue = data.filter((k: any) => k?.overdue);
      setOverdue(overdue?.length || 0);
      const totalBillingAmount = data.reduce(
        (previousValue: number, k: any) => Number(previousValue) + Number(k?.billingSettings?.amount || 0),
        0,
      );
      setTotalBillingAmount(Number(totalBillingAmount || 0));

      const meta: PagedMetaInterface = result.meta;
      if (meta) {
        setPagedMeta(meta);
      }
    } catch (e: any) {
      const message = String(e?.response?.data?.message || 'Error fetching list');
      return toast.error(message);
    } finally {
      setLoading(false);
    }
  };

  const verifyLastTransactionStatus = (row: any) => {
    let isError = false;
    const lasts = row?.lastTransactions?.length ? row?.lastTransactions : [];
    for (const status of lasts?.map((k: any) => k.lastTransactionStatus)) {
      if (['failed', 'requires_payment_method', 'canceled'].includes(status)) {
        isError = true;
        break;
      }
    }
    return isError;
  };

  const conditionalRowStyles = [
    {
      when: (row: any) => verifyLastTransactionStatus(row),
      style: {
        backgroundColor: 'rgb(246, 206, 216)',
        // color: 'white',
      },
    },
  ];

  const handlePerRowsChange = async (perPage: number) => {
    const newOptions = {
      ...pagedMeta,
      perPage,
    };
    const newFilteredOptions = {
      filters: filteredOptions.filters,
      options: createOptionsPage(newOptions),
    };
    setFilteredOptions(newFilteredOptions);
  };

  const handlePageChange = async (page: number) => {
    const newOptions = {
      ...pagedMeta,
      currentPage: page,
    };
    const newFilteredOptions = {
      filters: filteredOptions.filters,
      options: createOptionsPage(newOptions),
    };
    setFilteredOptions(newFilteredOptions);
  };

  const handleSearch = async (text: string) => {
    const filterFirstName = {
      field: 'firstName',
      operation: 'ilike',
      value: text || '',
    };
    const newFilteredOptions = {
      filters: [filterFirstName],
      options: filteredOptions.options,
    };
    newFilteredOptions.options.page = 1;
    setFilteredOptions(newFilteredOptions);
  };

  return (
    <>
      <CommonHeader />
      <Container className="mt--8" fluid>
        <Row>
          <Col xs="12">
            <Row className={`mb-4 ${isMobile ? 'mt-n4' : ''} ${isTablet ? 'mt-n5' : ''}`}>
              <Col lg="3" md="6" xl="3" xs="6" className={isMobile || isTablet ? 'mb-1' : ''}>
                <CardWidget
                  title="Billing Not Turned On"
                  text={`${billingNotTurnedOn}`}
                  icon="fas fa-filter"
                  iconColor="primary"
                  tag="h6"
                  cursor="pointer"
                  onClick={() => setCurrentFilter('BILLING')}
                  style={isSmall ? {} : { minHeight: '83px' }}
                />
              </Col>
              <Col lg="3" md="6" xl="3" xs="6" className={isMobile ? 'mb-1' : ''}>
                <CardWidget
                  title="Autocharge Not Turned On"
                  text={`${autochargeTurnedOn}`}
                  icon="fas fa-filter"
                  iconColor="info"
                  tag="h6"
                  cursor="pointer"
                  onClick={() => setCurrentFilter('AUTOCHARGE')}
                  style={isSmall ? {} : { minHeight: '83px' }}
                />
              </Col>
              <Col lg="3" md="6" xl="3" xs="6">
                <CardWidget
                  title="Overdue Accounts"
                  text={`${overdue}`}
                  icon="fas fa-filter"
                  iconColor="yellow"
                  tag="h6"
                  cursor="pointer"
                  onClick={() => setCurrentFilter('OVERDUE')}
                  style={isSmall ? {} : { minHeight: '83px' }}
                />
              </Col>
              <Col lg="3" md="6" xl="3" xs="6">
                <CardWidget
                  title="Total Billing Amount"
                  text={`$${totalBillingAmount.toFixed(2)}`}
                  icon="fas fa-filter"
                  iconColor="success"
                  tag="h6"
                  cursor="pointer"
                  style={isSmall ? {} : { minHeight: '83px' }}
                />
              </Col>
            </Row>
          </Col>
          <Col xs="12" className="mb-2">
            <Card>
              <CardHeader className="border-0 pb-0">
                <h3 className="mb-0">Filter</h3>
              </CardHeader>
              <CardBody className="pt-1 pb-1">
                <Row>
                  <Col xs="8" sm="6">
                    <FormGroup>
                      <Input type="select" onChange={(e) => setCurrentFilter(e.target.value)} value={currentFilter}>
                        {filtersList?.map((filter: any, index: number) => {
                          return (
                            <option value={filter.value} key={`filter-button-${index}`}>
                              {filter.label}
                            </option>
                          );
                        })}
                      </Input>
                    </FormGroup>
                  </Col>
                  <Col xs="4" sm="6">
                    {isAdmin(user) && (
                      <UncontrolledDropdown style={{ float: 'right' }}>
                        <DropdownToggle
                          href="#"
                          role="button"
                          size="sm"
                          color="primary"
                          onClick={(e) => e.preventDefault()}
                        >
                          Bulk
                        </DropdownToggle>

                        <DropdownMenu className="dropdown-menu-arrow" right>
                          <DropdownItem
                            onClick={(e) => {
                              e.preventDefault();
                              showBulkModal('CHARGE');
                            }}
                          >
                            Post a Charge
                          </DropdownItem>
                          <DropdownItem
                            onClick={(e) => {
                              e.preventDefault();
                              showBulkModal('PRICE');
                            }}
                          >
                            Price Change
                          </DropdownItem>
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    )}
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
          <Col xs="12">
            <DataTable
              title="Billing"
              columns={columns}
              data={data}
              totalRows={pagedMeta.total}
              loading={loading}
              button={false}
              buttonUrl=""
              onChangeRowsPerPage={handlePerRowsChange}
              onChangePage={handlePageChange}
              search={true}
              onSearch={handleSearch}
              conditionalRowStyles={conditionalRowStyles}
            />
          </Col>
        </Row>
      </Container>
      <BillingBulkKidsModal isOpen={bulkModal} bulkType={bulkType} toggleModal={() => setBulkModal(!bulkModal)} />
    </>
  );
};

export default BillingList;

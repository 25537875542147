import { useContext, useEffect, useState } from 'react';
import { Alert, Button, Col, FormGroup, Input, Modal, Row } from 'reactstrap';
import NumberFormat from 'react-number-format';
import { Context as AuthContext } from '../../../contexts/AuthContext';
import TransactionService from '../../../services/Transaction';
import { validTransactionTypes } from '../../../interfaces/TransactionsInterface';
import { toast } from 'react-toastify';
import UserCustomer from '../../../services/UserCustomer';
import BillingParentsModal from './BillingParentsModal';

type BillingParentPaymentModalProps = {
  parents: any[];
  kidsList: any[];
  isOpen: boolean;
  toggleModal: (e?: any) => void;
  onChange: (e?: any, amount?: number) => void;
  totalBalanceAmount?: number;
  isRequiredPayment?: boolean;
  invoiceId?: string;
};

const BillingParentPaymentModal = ({
  kidsList,
  isOpen,
  toggleModal,
  onChange,
  totalBalanceAmount,
  isRequiredPayment = false,
  invoiceId,
}: BillingParentPaymentModalProps) => {
  const { token, user, currentSchool }: any = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [description, setDescription] = useState('');
  const [amount, setAmount] = useState(0);
  const [payPercent, setPayPercent] = useState(0);

  const [kidsPercents, setKidsPercent] = useState([] as { id: string; name: string; percentage: number }[]);

  const [achMethods, setAchMethods] = useState([] as any[]);
  const [cardMethods, setCardMethods] = useState([] as any[]);

  const [cardAccountModal, setCardAccountModal] = useState(false);
  const [currentId, setCurrentId] = useState('');
  const [currentName, setCurrentName] = useState('');
  const [currentEmail, setCurrentEmail] = useState('');
  const [paymentMethodId, setPaymentMethodId] = useState('');
  const [transactionType, setTransactionType] = useState('');

  useEffect(() => {
    const percents = [];
    for (const k of kidsList) {
      if (k.percentage < 100) {
        setPayPercent(k.percentage);
      }

      percents.push({
        id: k.id,
        name: `${k.firstName || ''} ${k.lastName || ''}`,
        percentage: k.percentage || 0,
      });
    }
    setKidsPercent(percents);
    getUserCustomer();
  }, [isOpen]);

  useEffect(() => {
    setAmount(totalBalanceAmount || 0);
  }, [totalBalanceAmount]);

  const getUserCustomer = async () => {
    setLoading(true);
    const result = await UserCustomer(token).create({
      userId: user.id || '',
      schoolId: currentSchool?.value || '',
      options: { email: user.email || '' },
    });
    const customer = result?.data || {};

    const ach = customer?.options?.paymentMethods?.ACH || [];
    setAchMethods(ach);
    const cards = customer?.options?.paymentMethods?.CREDIT_CARD || [];
    setCardMethods(cards);
    setLoading(false);
  };

  const handleRequest = async (e: any) => {
    if (loading) return;
    e.preventDefault();

    setLoading(true);
    try {
      const body: any = {
        kidsPercents,
        userId: user.id,
        transactionType,
        amount,
        description,
        paymentMethodId,
      };
      if (invoiceId) {
        body.invoiceId = invoiceId || '';
      }
      await TransactionService(token).createPayment(body);
      toast.success('Payment made successfully');
      onManualModalChange(e);
      onChange(e);
      setLoading(false);
    } catch (e: any) {
      toast.error(e?.response?.data?.message || 'Request error');
      setLoading(false);
    }
  };

  const toggleManualModal = (e: any) => {
    if (isRequiredPayment) return;
    e.preventDefault();
    toggleModal(e);
    setDescription('');
    setAmount(0);
  };

  const onManualModalChange = (e: any) => {
    e.preventDefault();
    setDescription('');
    setAmount(0);
  };

  const openCardAccountModal = (id: string, name: string, email: string) => {
    setCurrentId(id);
    setCurrentName(name);
    setCurrentEmail(email);
    setCardAccountModal(true);
  };

  const toggleCardAccountModal = (e: any) => {
    e.preventDefault();
    getUserCustomer();
    setCardAccountModal(!cardAccountModal);
  };

  return (
    <>
      <Modal className="modal-dialog-centered" size="md" isOpen={isOpen} toggle={(e: any) => toggleManualModal(e)}>
        <div className="modal-header pb-1">
          <h5 className="modal-title">Add Payment</h5>
          {isRequiredPayment ? (
            <></>
          ) : (
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={(e) => toggleManualModal(e)}
            >
              <span aria-hidden={true}>×</span>
            </button>
          )}
        </div>
        <div className="modal-body pt-0">
          <Row className="mt-2">
            {isRequiredPayment && payPercent > 0 ? (
              <Col xl="12">
                <Alert color="warning">
                  Please note, your account is responsible for {Number(payPercent).toFixed(2)}% of the billing amount
                  for this child. If you choose to make a payment now, you will be required to pay full amount of the
                  total current balance. If this does not work for you, please call or see front desk for assistance.
                </Alert>
              </Col>
            ) : (
              <></>
            )}

            <Col xl="12">
              <FormGroup>
                <label className="form-control-label">Select Account Type/Card</label>
                <Input
                  type="select"
                  onChange={(e: any) => {
                    const value = e.target.value;
                    if (value === '') {
                      return;
                    }
                    const [type, id] = value.split('||');
                    setPaymentMethodId(id);
                    setTransactionType(type);
                  }}
                >
                  <option value="">Select a account type or card</option>
                  <optgroup label="ACH">
                    {achMethods?.map((item: any, index: number) => {
                      return (
                        <option value={validTransactionTypes.ACH + '||' + item.id} key={`ach-options-${index}`}>
                          {item.us_bank_account.bank_name}(********{item.us_bank_account.last4})
                        </option>
                      );
                    })}
                  </optgroup>
                  <optgroup label="CARD">
                    {cardMethods?.map((item: any, index: number) => {
                      return (
                        <option value={validTransactionTypes.CREDIT_CARD + '||' + item.id} key={`ach-options-${index}`}>
                          {item.card.brand} [{String(item.card.exp_month).padStart(2, '0') + '/' + item.card.exp_year}]
                          (********
                          {item.card.last4})
                        </option>
                      );
                    })}
                  </optgroup>
                </Input>
                <Button
                  color="success"
                  size="sm"
                  style={{ marginTop: '2px' }}
                  onClick={(e) => {
                    e.preventDefault();
                    openCardAccountModal(
                      user?.id,
                      user?.firstName ? `${user?.firstName} ${user?.lastName}` : '',
                      user?.email,
                    );
                  }}
                >
                  <i className="fas fa-plus"></i>&nbsp;Add new card/Account
                </Button>
              </FormGroup>
            </Col>
            <Col xl="12">
              <FormGroup>
                <label className="form-control-label">Amount</label>
                <Input
                  type="text"
                  thousandsGroupStyle="thousand"
                  prefix="$"
                  decimalSeparator="."
                  displayType="input"
                  thousandSeparator={true}
                  allowNegative={false}
                  decimalScale={2}
                  tag={NumberFormat}
                  value={amount}
                  disabled={!!totalBalanceAmount}
                  onValueChange={(e: any) => setAmount(e.floatValue)}
                />
              </FormGroup>
            </Col>
          </Row>
          <hr />
          <Row>
            <Col md="6 text-center">
              {isRequiredPayment ? (
                <></>
              ) : (
                <Button color="secondary" size="md" style={{ minWidth: '100px' }} onClick={(e) => toggleManualModal(e)}>
                  Cancel
                </Button>
              )}
            </Col>
            <Col md="6 text-center">
              <Button color="success" size="md" style={{ minWidth: '100px' }} onClick={(e) => handleRequest(e)}>
                Charge Payment
              </Button>
            </Col>
          </Row>
        </div>
      </Modal>
      <BillingParentsModal
        isOpen={cardAccountModal}
        toggleModal={toggleCardAccountModal}
        id={currentId}
        name={currentName}
        email={currentEmail}
      />
    </>
  );
};

export default BillingParentPaymentModal;

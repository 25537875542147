import { isMobile } from 'react-device-detect';
import { Row, Col } from 'reactstrap';
import CardWidget from '../../../components/Cards/CardWidgetBilling';

const BillingSummary = ({
  tuitionBase,
  currentDiscount,
  billingAmount,
  currentBalance,
}: {
  tuitionBase?: number;
  currentDiscount?: number;
  billingAmount?: number;
  currentBalance?: number;
}) => {
  return (
    <Row className={isMobile ? 'mb-1' : 'mb-4'}>
      <Col
        xs="4"
        lg="3"
        xl="3"
        className={isMobile ? 'mb-1' : ''}
        style={isMobile ? { paddingLeft: 5, paddingRight: 5 } : {}}
      >
        <CardWidget
          title="Tuition Base"
          text={`$${Number(tuitionBase || 0).toFixed(2)}`}
          icon="fas fa-file-invoice-dollar"
          iconColor="primary"
        />
      </Col>
      <Col
        xs="4"
        lg="3"
        xl="3"
        className={isMobile ? 'mb-1' : ''}
        style={isMobile ? { paddingLeft: 5, paddingRight: 5 } : {}}
      >
        <CardWidget
          title={<>Discounts</>}
          text={`${Number(currentDiscount || 0).toFixed(2)}%`}
          icon="fas fa-percent"
          iconColor="info"
        />
      </Col>
      <Col
        xs="4"
        lg="3"
        xl="3"
        className={isMobile ? 'mb-1' : ''}
        style={isMobile ? { paddingLeft: 5, paddingRight: 5 } : {}}
      >
        <CardWidget
          title="Billing Amount"
          text={`$${Number(billingAmount || 0).toFixed(2)}`}
          icon="fas fa-dollar-sign"
          iconColor="yellow"
        />
      </Col>
      <Col xs="12" lg="3" xl="3" style={isMobile ? { paddingLeft: 5, paddingRight: 5 } : {}}>
        <CardWidget
          title="Current Balance"
          text={`$${Number(Number(currentBalance || 0) * -1).toFixed(2)}`}
          icon="fas fa-wallet"
          iconColor={`${Number(currentBalance || 0) >= 0 ? 'success' : 'danger'}`}
        />
      </Col>
    </Row>
  );
};

export default BillingSummary;

import { ToastContainer } from 'react-toastify';
import { Router } from 'react-router-dom';
import { PullToRefresh, PullDownContent, ReleaseContent, RefreshContent } from 'react-js-pull-to-refresh';
import './assets/plugins/nucleo/css/nucleo.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import './assets/scss/main.scss';
import 'react-toastify/dist/ReactToastify.css';
import 'react-confirm-alert/src/react-confirm-alert.css';
import 'rc-time-picker/assets/index.css';
import 'react-datepicker/dist/react-datepicker.css';

import Routes from './routes';
import history from './history';

import { AuthProvider } from './contexts/AuthContext';
import { isMobile } from 'react-device-detect';

const App = () => {
  const refreshPage = async () => {
    window.location.reload();
    return;
  };
  return (
    <AuthProvider>
      <Router history={history}>
        <div style={{ paddingTop: 'env(safe-area-inset-top)' }}>
          {isMobile ? (
            <PullToRefresh
              pullDownContent={<PullDownContent />}
              releaseContent={<ReleaseContent />}
              refreshContent={<RefreshContent />}
              pullDownThreshold={200}
              onRefresh={() => refreshPage()}
              triggerHeight={500}
              backgroundColor="white"
              startInvisible={true}
            >
              <ToastContainer autoClose={4000} style={{ paddingTop: 'env(safe-area-inset-top)' }} />
              <Routes />
            </PullToRefresh>
          ) : (
            <>
              <ToastContainer autoClose={4000} style={{ paddingTop: 'env(safe-area-inset-top)' }} />
              <Routes />
            </>
          )}
        </div>
      </Router>
    </AuthProvider>
  );
};

export default App;

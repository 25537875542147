import { PushNotifications } from '@capacitor/push-notifications';
import { Device } from '@capacitor/device';
import { Preferences } from '@capacitor/preferences';

export const initPush = async () => {
  const info = await Device.getInfo();
  if (info.platform !== 'web') {
    await registerNotifications();
    await addListeners();
  }
};

export const setPushToken = async (token: string) => {
  try {
    const info = await Device.getInfo();
    if (info.platform !== 'web') {
      await Preferences.set({
        key: 'x-push-notifications-token',
        value: JSON.stringify({ token: token, timestamp: Date.now() }),
      });
    }
  } catch (e) {
    console.error('ERROR ON SET TOKEN:: ', e);
    return '';
  }
};

export const getPushToken = async () => {
  try {
    const info = await Device.getInfo();
    if (info.platform !== 'web') {
      const ret: any = await Preferences.get({ key: 'x-push-notifications-token' });
      const token = JSON.parse(ret.value);
      return token || '';
    }
  } catch (e) {
    console.error('ERROR ON GET TOKEN:: ', e);
    return '';
  }
};

export const addListeners = async () => {
  await PushNotifications.addListener('registration', async (token: any) => {
    if (token?.value) {
      await setPushToken(token.value);
    }
    console.info('Registration token: ', token?.value);
  });

  await PushNotifications.addListener('registrationError', (err: any) => {
    console.error('Registration error: ', err.error);
  });

  await PushNotifications.addListener('pushNotificationReceived', (notification: any) => {
    console.log('Push notification received: ', notification);
  });

  await PushNotifications.addListener('pushNotificationActionPerformed', (notification: any) => {
    console.log('Push notification action performed', notification.actionId, notification.inputValue);
  });
};

export const registerNotifications = async () => {
  let permStatus = await PushNotifications.checkPermissions();

  if (permStatus.receive === 'prompt') {
    permStatus = await PushNotifications.requestPermissions();
  }

  if (permStatus.receive !== 'granted') {
    console.error('User denied notifications permissions!');
  }

  await PushNotifications.register();
};

export const getDeliveredNotifications = async () => {
  const notificationList = await PushNotifications.getDeliveredNotifications();
  console.log('delivered notifications', notificationList);
};

import EmployeeInterface from '../interfaces/EmployeeInterface';
import { FilteredOptionsInterface, makeQueryParamsForRequest } from '../entities/FilteredOptions';
import http from '../helpers/http';
import NewHireInterface from '../interfaces/NewHireInterface';

const Employee = (token: string) => {
  const headers = {
    'x-access-token': token,
  };

  return {
    updatePtoSpec: async (userId: string, body: any) => {
      const result = await http.put(`/employees/${userId}/pto-spec`, body, { headers });
      return result.data;
    },
    update: async (userId: string, body: Partial<EmployeeInterface>) => {
      const result = await http.put(`/employees/${userId}`, body, { headers });
      return result.data;
    },
    list: async (filteredOptions?: FilteredOptionsInterface) => {
      const params = makeQueryParamsForRequest(filteredOptions);
      const result = await http.get('/employees', {
        headers,
        params,
      });
      return result.data;
    },
    show: async (id: string) => {
      const result = await http.get(`/employees/${id}`, {
        headers,
      });
      return result.data;
    },
    create: async (body: Partial<EmployeeInterface>) => {
      const result = await http.post(`/employees`, body, { headers });
      return result.data;
    },
    activated: async (id: string) => {
      const result = await http.patch(`/employees/activate/${id}`, {});
      return result;
    },
    createNewHire: async (body: Partial<NewHireInterface>) => {
      const result = await http.post(`/employees/new-hire`, body, { headers });
      return result.data;
    },
    revokeNewHire: async (employeeId: string) => {
      const result = await http.delete(`/employees/new-hire/revoke/${employeeId}`, { headers });
      return result.data;
    },
    newHireValidationToken: async (token: string) => {
      const result = await http.get(`/employees/new-hire/validation/${token}`, {});
      return result;
    },
    documentsValidationToken: async (token: string) => {
      const result = await http.get(`/employees/documents/validation/${token}`, {});
      return result;
    },
    acceptNewHire: async (token: string) => {
      const result = await http.patch(`/employees/new-hire/accept/${token}`, {});
      return result;
    },
    resendNewHire: async (employeeId: string) => {
      const result = await http.patch(`/employees/new-hire/resend/${employeeId}`);
      return result;
    },
    removeAccess: async (employeeId: string) => {
      const result = await http.patch(`/employees/new-hire/remove-access/${employeeId}`, {}, { headers });
      return result;
    },
    putOnLeave: async (employeeId: string) => {
      const result = await http.patch(`/employees/new-hire/put-on-leave/${employeeId}`, {}, { headers });
      return result;
    },
    endPutOnLeave: async (employeeId: string) => {
      const result = await http.patch(`/employees/new-hire/end-put-on-leave/${employeeId}`, {}, { headers });
      return result;
    },
    rejectNewHire: async (token: string) => {
      const result = await http.patch(`/employees/new-hire/reject/${token}`);
      return result;
    },
    listBySchool: async (schoolId: string) => {
      const result = await http.get(`/employees/${schoolId}/expiration-dates`, {
        headers,
      });
      return result.data;
    },
    listCanReciveSupportTickets: async (schoolId: string) => {
      const result = await http.get(`/employees/can-recive-support-tickets/${schoolId}`, {
        headers,
      });
      return result.data;
    },
    listEmployeesBySchool: async (schoolId: string, search = '') => {
      const result = await http.get(`/employees/by-school/${schoolId}?search=${search}`, {
        headers,
      });
      return result.data;
    },
    updateMultiplePtoPolices: async (userIds: string[], ptoPolicyId: string) => {
      const result = await http.put(`/employees/update-multiple-pto-policies`, { userIds, ptoPolicyId }, { headers });
      return result.data;
    },
  };
};

export default Employee;

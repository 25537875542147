import { Modal, Row, Col, Button, FormGroup, Input } from 'reactstrap';
import { useContext, useState } from 'react';
import { Context as AuthContext } from '../../../contexts/AuthContext';
import ClassReportService from '../../../services/ClassReport';
import { toast } from 'react-toastify';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import * as XLSX from 'xlsx-js-style';
import * as FileSaver from 'file-saver';

type ClassTrackingReportModalProps = {
  isOpen: boolean;
  classId: string;
  toggleModal: (e?: any) => void;
};

const ClassTrackingReportModal = ({ isOpen, classId, toggleModal }: ClassTrackingReportModalProps) => {
  const { token }: any = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [date, setDate] = useState(new Date());

  const textCenter = (text: string) => {
    return {
      v: text,
      t: 's',
      s: { alignment: { horizontal: 'center' } },
    };
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (loading) return;

    setLoading(true);

    try {
      const response = await ClassReportService(token).transition(classId, moment(date).format('YYYY-MM-DD'));
      const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';

      const verticalHeaderStyle = {
        alignment: { textRotation: 90, vertical: 'center', horizontal: 'center', wrapText: true },
        fill: { fgColor: { rgb: 'E9E9E9' } },
        font: { bold: true },
      };
      const classType = response.data.meta.classType;
      const hasNaps: boolean = response.data.meta.classType === 'permanent';
      let lineOneHeaders: any[] = [];
      let napsHeaders = [
        { v: 'Nap 1', t: 's', s: verticalHeaderStyle },
        '',
        { v: 'Nap 2', t: 's', s: verticalHeaderStyle },
        '',
        { v: 'Nap 3', t: 's', s: verticalHeaderStyle },
        '',
      ];
      if (!hasNaps) {
        napsHeaders = [];
      }

      lineOneHeaders.push({
        v: `Classroom: ${response.data.meta.class}`,
        t: 's',
        s: { alignment: { horizontal: 'center' } },
      });
      lineOneHeaders.push('');
      if (classType === 'permanent') {
        lineOneHeaders.push({ v: 'Time to Arrival into classroom', t: 's', s: verticalHeaderStyle });
      }
      lineOneHeaders = [...lineOneHeaders, ...napsHeaders];
      const lineTwoHeaders: any[] = [
        {
          v: `Data: ${moment(response.data.meta.date).format('YYYY/DD/MM')}`,
          t: 's',
          s: { alignment: { vertical: 'top', horizontal: 'center' } },
        },
        '',
        '',
      ];
      let merges: any[] = [];
      if (classType === 'permanent') {
        merges = [
          { s: { c: 0, r: 0 }, e: { c: 1, r: 0 } },
          { s: { c: 0, r: 1 }, e: { c: 1, r: 1 } },
          { s: { c: 2, r: 0 }, e: { c: 2, r: 1 } },
          { s: { c: 3, r: 0 }, e: { c: 4, r: 1 } },
          { s: { c: 5, r: 0 }, e: { c: 6, r: 1 } },
          { s: { c: 7, r: 0 }, e: { c: 8, r: 1 } },
        ];
      }
      response.data.headers.forEach((item: any) => {
        lineOneHeaders.push({ v: item.title, t: 's', s: verticalHeaderStyle });
        lineOneHeaders.push('');
        lineTwoHeaders.push('');
        lineTwoHeaders.push('');
        merges.push({ s: { c: lineOneHeaders.length - 2, r: 0 }, e: { c: lineOneHeaders.length - 1, r: 1 } });
      });
      if (classType === 'permanent') {
        lineOneHeaders.push({
          v: 'Departune to go Home',
          t: 's',
          s: verticalHeaderStyle,
        });
      }
      merges.push({ s: { c: lineOneHeaders.length - 1, r: 0 }, e: { c: lineOneHeaders.length - 1, r: 1 } });

      const wb = XLSX.utils.book_new();

      const xlsData = [
        lineOneHeaders,
        lineTwoHeaders,
        [
          ...[
            {
              v: 'Child`s First/Last Name',
              t: 's',
              s: { alignment: { horizontal: 'center' }, font: { bold: true }, fill: { fgColor: { rgb: 'E9E9E9' } } },
            },
            {
              v: 'Age',
              t: 's',
              s: { alignment: { horizontal: 'center' }, font: { bold: true }, fill: { fgColor: { rgb: 'E9E9E9' } } },
            },
          ],
          ...lineOneHeaders
            .filter((_: any, i) => i > 1)
            .map((item: any, i: number) => {
              if (i === 0 && classType === 'permanent')
                return { v: 'TIME IN', t: 's', s: { font: { bold: true }, fill: { fgColor: { rgb: 'E9E9E9' } } } };
              if (i === lineOneHeaders.length - 3 && classType === 'permanent')
                return { v: 'TIME OUT', t: 's', s: { font: { bold: true }, fill: { fgColor: { rgb: 'E9E9E9' } } } };
              if (classType !== 'permanent') {
                return {
                  v: item !== '' ? 'FROM' : 'TO',
                  t: 's',
                  s: { font: { bold: true }, fill: { fgColor: { rgb: 'E9E9E9' } } },
                };
              }
              return {
                v: item === '' ? 'FROM' : 'TO',
                t: 's',
                s: { font: { bold: true }, fill: { fgColor: { rgb: 'E9E9E9' } } },
              };
            }),
        ],
        ...response.data.body.kids.map((item: any) => {
          const kidsMovement: any[] = [];
          const naps: any[] = [];
          let kidsLine: any[] = [];
          item.kidsMovement.forEach((mov: any) => {
            if (classType === 'permanent') {
              kidsMovement.push(textCenter(mov.checkIn));
            }
            kidsMovement.push(textCenter(mov.checkOut));
            if (classType !== 'permanent') {
              kidsMovement.push(textCenter(mov.checkIn));
            }
          });
          if (hasNaps) {
            for (let i = 0; i < 3; i++) {
              naps.push(textCenter(item.naps[i]?.sleep || '-'));
              naps.push(textCenter(item.naps[i]?.awake || '-'));
            }
          }
          kidsLine.push(item.childName);
          kidsLine.push(textCenter(item.age));
          if (classType === 'permanent') {
            kidsLine.push(textCenter(item.checkIn));
          }
          kidsLine = [...kidsLine, ...naps];
          kidsLine = [...kidsLine, ...kidsMovement];
          if (classType === 'permanent') {
            kidsLine.push(textCenter(item.checkOut));
          }
          return kidsLine;
        }),
        [],
      ];

      let maxTeacherChecks = 0;
      let teachersTable = response.data.body.teachers.map((t: any) => {
        if (maxTeacherChecks < t.times.length * 2) {
          maxTeacherChecks = t.times.length * 2;
        }
        const times: any[] = [];
        t.times.forEach((time: any) => {
          times.push(textCenter(time.timeIn));
          times.push(textCenter(time.timeOut));
        });
        return [t.name, ...times];
      });
      const teachersHeaders = [];
      for (let i = 0; i < maxTeacherChecks; i++) {
        teachersHeaders.push({
          v: i % 2 === 0 ? 'Time In' : 'Time Out',
          t: 's',
          s: { alignment: { horizontal: 'center' }, font: { bold: true }, fill: { fgColor: { rgb: 'E9E9E9' } } },
        });
      }
      teachersTable = [
        [
          {
            v: 'Teacher`s Name',
            t: 's',
            s: { alignment: { horizontal: 'center' }, font: { bold: true }, fill: { fgColor: { rgb: 'E9E9E9' } } },
          },
          ...teachersHeaders,
        ],
      ].concat(teachersTable);

      const ws = XLSX.utils.aoa_to_sheet([...xlsData, ...teachersTable]);
      ws['!merges'] = merges;
      if (!ws['!rows']) ws['!rows'] = [];
      if (!ws['!rows'][0]) ws['!rows'][0] = { hpx: 50 };
      if (!ws['!rows'][1]) ws['!rows'][1] = { hpx: 50 };
      if (!ws['!cols']) ws['!cols'] = [];
      if (!ws['!cols'][0]) ws['!cols'][0] = { wch: 25 };
      XLSX.utils.book_append_sheet(wb, ws, 'sheet 1');

      const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
      const data = new Blob([excelBuffer], { type: fileType });
      FileSaver.saveAs(
        data,
        ('tracking-report_' + response.data.meta.class + '_' + response.data.meta.date + '.xlsx').toLocaleLowerCase(),
      );
    } catch (e: any) {
      const message = String(e?.response?.data?.message || 'Report error!');
      toast.error(message);
    } finally {
      setLoading(false);
    }
  };

  const handleDateChange = (d: Date) => {
    setDate(d);
  };

  return (
    <>
      <Modal className="modal-dialog-centered" isOpen={isOpen} toggle={(e: any) => toggleModal(e)}>
        <div className="modal-header pb-1">
          <h5 className="modal-title">Transition Report</h5>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={(e) => toggleModal(e)}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div className="modal-body pt-0">
          <Row>
            <Col>
              <Row>
                <Col lg="12">
                  <FormGroup>
                    <label className="form-control-label">Date</label>
                    <Input
                      tag={DatePicker}
                      dateFormat="MM/dd/yyyy"
                      onChange={(d: any) => handleDateChange(d)}
                      selected={date}
                    />
                    <Button
                      color="primary"
                      type="button"
                      size="sm"
                      className="mt-1"
                      style={{ minWidth: '100px' }}
                      onClick={(e) => handleSubmit(e)}
                    >
                      OK
                    </Button>
                  </FormGroup>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </Modal>
    </>
  );
};

export default ClassTrackingReportModal;

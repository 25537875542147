import { Button, Card, CardHeader, CardBody, Row, Col, Table } from 'reactstrap';
import { Link } from 'react-router-dom';
import { useContext } from 'react';

import { Context as AuthContext } from '../../../contexts/AuthContext';
import isAdmin from '../../../helpers/isAdmin';

type ClassDetailedTableProps = {
  id: string;
  cameraUri?: boolean;
  name: string;
  legalRatioTeachers: number;
  legalRatioChildrens: number;
  teachers: number;
  capacity: number;
  enrolled: number;
  notStarted: number;
  checkedIn: number;
  movedOut: number;
  movedIn: number;
  toggleTeachersModal: (id: string) => void;
  toggleEnrolledModal: (id: string, className: string) => void;
  toggleNotStartedModal: (id: string, className: string) => void;
};

const ClassDetailedTable = ({
  id,
  cameraUri,
  name,
  legalRatioTeachers,
  legalRatioChildrens,
  teachers,
  capacity,
  enrolled,
  notStarted,
  checkedIn,
  movedOut,
  movedIn,
  toggleTeachersModal,
  toggleEnrolledModal,
  toggleNotStartedModal,
}: ClassDetailedTableProps) => {
  const { user }: any = useContext(AuthContext);
  const canViewEdit = isAdmin(user);
  const allChildrenInClass = checkedIn - movedOut + movedIn;
  return (
    <Col xl="4" lg="6" md="12">
      <Card
        className={
          'mb-3 ' + (legalRatioTeachers / legalRatioChildrens > teachers / allChildrenInClass ? 'border-danger' : '')
        }
        style={{ border: '1px solid rgba(0, 0, 0, 0.1)' }}
      >
        <CardHeader>
          <Row>
            <Col>
              <h3 className="mt-1 mb-0">{name}</h3>
            </Col>
            <Col className="col-auto">
              {cameraUri && (
                <div
                  className="icon icon-shape bg-danger text-white rounded-circle shadow"
                  style={{ width: '2rem', height: '2rem' }}
                >
                  <i className="fas fa-camera" style={{ fontSize: '1rem' }}></i>
                </div>
              )}
              {canViewEdit && (
                <a
                  href={`/class/${id}`}
                  className="icon icon-shape bg-success text-white rounded-circle shadow ml-2"
                  style={{ width: '2rem', height: '2rem' }}
                >
                  <i className="fas fa-pen" style={{ fontSize: '1rem' }}></i>
                </a>
              )}
            </Col>
          </Row>
        </CardHeader>
        <CardBody>
          <Table striped bordered responsive>
            <tbody>
              <tr>
                <th>Ratio</th>
                <td>
                  <strong>
                    {legalRatioTeachers && legalRatioChildrens ? `${legalRatioTeachers}:${legalRatioChildrens}` : '0:0'}
                  </strong>
                </td>
              </tr>
              <tr>
                <th>Teachers</th>
                <td>
                  <Link
                    to="#"
                    onClick={(e) => {
                      e.preventDefault();
                      toggleTeachersModal(id);
                    }}
                  >
                    <strong>{teachers || 0}</strong>
                  </Link>
                </td>
              </tr>
              <tr>
                <th>Enrolled</th>
                <td>
                  <Link
                    to="#"
                    onClick={(e) => {
                      e.preventDefault();
                      toggleEnrolledModal(id, name);
                    }}
                  >
                    <strong> {`${enrolled || 0}`}</strong>
                  </Link>
                  {` / ${capacity || 0}`}
                </td>
              </tr>
              <tr>
                <th>Not started</th>
                <td>
                  <Link
                    to="#"
                    onClick={(e) => {
                      e.preventDefault();
                      toggleNotStartedModal(id, name);
                    }}
                  >
                    <strong>{notStarted || 0}</strong>
                  </Link>
                </td>
              </tr>
              <tr>
                <th>Checked In</th>
                <td>
                  <strong>{allChildrenInClass || 0}</strong>
                </td>
              </tr>
            </tbody>
          </Table>
          <Link to={`/class/${id}/details`}>
            <Button
              className={`btn-block mt-2 ml-0`}
              color="success"
              size="md"
              style={{ minWidth: 124, minHeight: 28 }}
            >
              Manage this class
            </Button>
          </Link>
        </CardBody>
      </Card>
    </Col>
  );
};

export default ClassDetailedTable;

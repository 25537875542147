import { useHistory, useLocation } from 'react-router-dom';
import { Container, Row } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { isMobile } from 'react-device-detect';

const CommonHeader = () => {
  const history = useHistory();
  const location = useLocation();
  return (
    <>
      <div className="header bg-gradient-info pb-8 pt-5 pt-md-8">
        <Container fluid>
          <div className="header-body">
            <Row>
              {isMobile && location?.pathname !== '/home' ? (
                <FontAwesomeIcon
                  onClick={() => history.goBack()}
                  icon={faArrowLeft}
                  color="#FFF"
                  border={true}
                  className="ml-3"
                  size="lg"
                  style={{ marginTop: -38, marginBottom: 38 }}
                />
              ) : (
                <></>
              )}
            </Row>
          </div>
        </Container>
      </div>
    </>
  );
};

export default CommonHeader;

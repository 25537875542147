import { Col, Modal, Row, Table } from 'reactstrap';
import { toLocaleFormatSlash } from '../../../helpers/dateFormat';

type EnrollmentInformationsModalProps = {
  isOpen: boolean;
  kids: any;
  toggleModal: (e?: any) => void;
};

const EnrollmentInformationsModal = ({ isOpen, kids, toggleModal }: EnrollmentInformationsModalProps) => {
  return (
    <>
      <Modal className="modal-dialog-centered" isOpen={isOpen} toggle={(e: any) => toggleModal(e)}>
        <div className="modal-header pb-1">
          <h5 className="modal-title">Enrollment Details</h5>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={(e) => toggleModal(e)}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div className="modal-body pt-0">
          <Row>
            <Col>
              <Table className="align-items-center table-flush" responsive>
                <tbody>
                  <tr>
                    <th>Full Name</th>
                    <td>{`${kids?.firstName} ${kids?.lastName}`}</td>
                  </tr>
                  <tr>
                    <th>Birthdate</th>
                    <td>{kids?.birthday ? `${toLocaleFormatSlash(kids?.birthday)}` : '-'}</td>
                  </tr>
                  {/* <tr>
                    <th>School Before</th>
                    <td>Yes</td>
                  </tr>
                  <tr>
                    <th>Age Group Class or VPK Options</th>
                    <td>Room 4 - 2 Year Olds</td>
                  </tr> */}
                  <tr>
                    <th>Class Type</th>
                    <td>{kids?.enrollment?.classType?.name || '-'}</td>
                  </tr>
                </tbody>
              </Table>
            </Col>
          </Row>
          <h6 className="heading-small text-muted mb-4 mt-4">Parent Details</h6>
          <Row>
            <Col>
              <Table className="align-items-center table-flush" responsive>
                <tbody>
                  <tr>
                    <th>Name</th>
                    <td>{`${kids?.kidsUsersRelations?.user?.firstName || ''} ${
                      kids?.kidsUsersRelations?.user?.lastName || ''
                    }`}</td>
                  </tr>
                  <tr>
                    <th>Email</th>
                    <td>{kids?.kidsUsersRelations?.user?.email || '-'}</td>
                  </tr>
                  <tr>
                    <th>Phone</th>
                    <td>{kids?.kidsUsersRelations?.user?.phone || '-'}</td>
                  </tr>
                </tbody>
              </Table>
            </Col>
          </Row>
          <h6 className="heading-small text-muted mb-4 mt-4">Payment Details</h6>
          <Row>
            <Col>
              <Table className="align-items-center table-flush" responsive>
                <tbody>
                  <tr>
                    <th>Stripe ID</th>
                    <td>{kids?.transactionRegFee?.providerId}</td>
                  </tr>
                  <tr>
                    <th>Reg. Amount</th>
                    <td>{Number(kids?.transactionRegFee?.amount || 0).toFixed(2)}</td>
                  </tr>
                  <tr>
                    <th>Last 4</th>
                    <td>{kids?.transactionRegFee?.options?.card?.last4 || '-'}</td>
                  </tr>
                  <tr>
                    <th>Receipt</th>
                    <td>
                      <a href={kids?.transactionRegFee?.receipt || '#'} target="_blank">
                        Click here
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <th>Tuition Amount</th>
                    <td>{Number(kids?.billingSettings?.amount || 0).toFixed(2)}</td>
                  </tr>
                </tbody>
              </Table>
            </Col>
          </Row>
        </div>
      </Modal>
    </>
  );
};

export default EnrollmentInformationsModal;
